import React from "react";
import "../css/main.css";
import { useMediaQuery } from "react-responsive";
import img_recife from "../../assets/images/img_recife.png";
import img_campinas from "../../assets/images/img_campinas.png";
import img_BH from "../../assets/images/img_belohorizonte.png";
import img_joseCampos from "../../assets/images/img_saojosedoscampos.png";
import img_manaus from "../../assets/images/img_manaus.png";
import { useTranslation } from "react-i18next";

export default function SectionContato() {
  const { t } = useTranslation();

  const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  return (
    <section className="section-contato" id="contacts-section">
      <div className="container">
        <div className="row">
          <div className="col">
            <h2 className="wow fadeIn" data-wow-delay="0.4s">
              {t("menu.contato")}
            </h2>
          </div>
        </div>
        {!isMobile && (
          <div className="row justify-content-center mb-4">
            <div className="col-auto">
              <div className="fones">
                <p>
                  {t("home.fone")}: <b>(19) 2137.6803</b>
                </p>
              </div>
            </div>

            <div className="col-auto">
              <div className="fones" id="whatsapp">
                <p>
                  Whatsapp: <b>(19) 98124-3389</b>
                </p>
              </div>
            </div>
          </div>
        )}

        {isMobile && (
          <div
            className="row justify-content-center mb-4"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div
              style={{ width: 165, display: "flex", justifyContent: "center" }}
            >
              <div className="fones">
                <p style={{ textAlign: "center" }}>
                  Fone: <b>(19) 2137.6803</b>
                </p>
              </div>
            </div>

            <div
              style={{ width: 165, display: "flex", justifyContent: "center" }}
            >
              <div className="fones" id="whatsapp">
                <p style={{ textAlign: "center" }}>
                  Whatsapp:
                  <br /> <b>(19)98208.4396</b>
                </p>
              </div>
            </div>
          </div>
        )}

        <div className="row" style={{ justifyContent: "center" }}>
          <div
            className="col-sm-6 col-md-3 mb-3"
            style={{ width: isMobile ? "325px" : "230px" }}
          >
            <div
              className="ctn-contato text-center wow fadeInDown"
              data-wow-delay="0.2s"
            >
              <img src={img_recife} alt="" className="img-fluid" />
              <h3 translate="no" style={{ textAlign: "center" }}>
                Recife/PE
              </h3>
              <br />
              <p style={{ textAlign: "center" }}>
                Cais do Apolo, 222 - 12 andar - Bairro do Recife - CEP:
                50030-230 - Recife, PE
              </p>
            </div>
          </div>

          <div
            className="col-sm-6 col-md-3 mb-3"
            style={{ width: isMobile ? "325px" : "230px" }}
          >
            <div
              className="ctn-contato text-center wow fadeInDown"
              data-wow-delay="0.4s"
            >
              <img src={img_campinas} alt="" className="img-fluid" />
              <h3 translate="no" style={{ textAlign: "center" }}>
                Campinas/SP
              </h3>
              <br />
              <p style={{ textAlign: "center" }}>
                Rua Aguaçu, 79, Bairro Alphaville CEP: 13098-321 - Campinas, SP
              </p>
            </div>
          </div>

          <div
            className="col-sm-6 col-md-3 mb-3"
            style={{ width: isMobile ? "325px" : "225px" }}
          >
            <div
              className="ctn-contato text-center wow fadeInDown"
              data-wow-delay="0.6s"
            >
              <img src={img_BH} alt="" className="img-fluid" />
              <h3 translate="no" style={{ textAlign: "center" }}>
                Belo Horizonte/MG
              </h3>
              <br />
              <p style={{ textAlign: "center" }}>
                Av Cristóvão Colombo, 485 8º Andar CEP: 30140-150 – Belo
                Horizonte, MG
              </p>
            </div>
          </div>

          <div
            className="col-sm-6 col-md-3 mb-3"
            style={{ width: isMobile ? "325px" : "230px" }}
          >
            <div
              className="ctn-contato text-center wow fadeInDown"
              data-wow-delay="0.8s"
              style={{ justifyContent: "center" }}
            >
              <img src={img_joseCampos} alt="" className="img-fluid" />
              <h3 translate="no" style={{ textAlign: "center" }}>
                S. José dos Campos/SP
              </h3>
              <p style={{ textAlign: "center" }}>
                Estrada Altino Bordensan, 500, Sala 2105 Eugênio de Mello -
                Parque Tecnológico CEP: 12247-016 - São José dos Campos, SP
              </p>
            </div>
          </div>
          <div
            className="col-sm-6 col-md-3 mb-3"
            style={{ width: isMobile ? "325px" : "225px" }}
          >
            <div
              className="ctn-contato text-center wow fadeInDown"
              data-wow-delay="0.8s"
            >
              <img src={img_manaus} alt="" className="img-fluid" />
              <h3 translate="no" style={{ textAlign: "center" }}>
                Manaus/AM
              </h3>
              <br />
              <p
                id="contato"
                style={{ textAlign: "center", marginBottom: "10px" }}
              >
                Av Djalma Batista, 1719, Sala 906 Bairro Chapada, CEP: 69050-010
                - Manaus, AM
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
