import React from "react";
import "../css/main.css";
import "./style.css";
import logoFooter from "../../assets/images/logo-footer.svg";
import seloISO from "../../assets/images/selo-iso-27001.png";
import menuFooter from "../../assets/images/menu-footer.svg";
import { useMediaQuery } from "react-responsive";
import linkedin from "../../assets/images/social/linkedin.svg";
import youtube from "../../assets/images/social/youtube.svg";
import privacidade from "../../assets/pdf/privacidade.pdf";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

export default function Footer() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-height: 1224px)" });
  return (
    <footer>
      <div className="container" id="footer">
        {!isMobile ? (
          <div className="row justify-content-center">
            <div className="col-auto text-center">
              <nav className="navbar navbar-expand-lg menu-footer">
                <button
                  className="navbar-toggler button-menu-footer"
                  type="button"
                  data-toggle="collapse"
                  data-target="#menufooter"
                  aria-controls="menufooter"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon">
                    <img src={menuFooter} alt="" className="img-fluid" />
                  </span>
                </button>
                <div className="collapse navbar-collapse" id="menufooter">
                  <ul className="navbar-nav mr-auto">
                    <li className="nav-item">
                      <Link className="nav-link" to="/home#a-fitec-section">
                        {t("menu.sobre")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <HashLink
                        className="nav-link"
                        to="/inovacao#inicio-inovacao"
                      >
                        {t("atuacao.pd")}
                      </HashLink>
                    </li>
                    <li className="nav-item">
                      <HashLink
                        className="nav-link"
                        to="/engenharia#inicio-engenharia"
                      >
                        {t("atuacao.engenharia")}
                      </HashLink>
                    </li>
                    <li className="nav-item">
                      <HashLink
                        className="nav-link"
                        to="/solucoes#imagem-solucoes"
                      >
                        {t("menu.solucao")}
                      </HashLink>
                    </li>
                    <li className="nav-item">
                      <HashLink
                        className="nav-link"
                        to="/posts#inicio-publicacoes"
                      >
                        {t("menu.publicacoes")}
                      </HashLink>
                    </li>
                    <li className="nav-item">
                      <HashLink
                        className="nav-link"
                        to="/oportunidade#trabalhe-conosco"
                      >
                        {t("menu.oportunidades")}
                      </HashLink>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/home#contacts-section">
                        {t("menu.contato")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        ) : (
          <div
            className="row justify-content-center"
            style={{ padding: "9px", paddingTop: "0", marginBottom: "10px" }}
          >
            <div className="col-auto">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <HashLink
                    className="nav-link"
                    to="/home#a-fitec-section"
                    style={{ color: "white", fontSize: "15px" }}
                  >
                    {t("menu.sobre")}
                  </HashLink>
                </li>
              </ul>
            </div>
            <div className="col-auto">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <HashLink
                    className="nav-link"
                    to="/inovacao#inicio-inovacao"
                    style={{ color: "white", fontSize: "15px" }}
                  >
                    {t("atuacao.pd")}
                  </HashLink>
                </li>
              </ul>
            </div>
            <div className="col-auto">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <HashLink
                    className="nav-link"
                    to="/engenharia#inicio-engenharia"
                    style={{ color: "white", fontSize: "15px" }}
                  >
                    {t("atuacao.engenharia")}
                  </HashLink>
                </li>
              </ul>
            </div>
            <div className="col-auto">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <HashLink
                    className="nav-link"
                    to="/solucoes#sustentabilidade"
                    style={{ color: "white", fontSize: "15px" }}
                  >
                    {t("menu.solucao")}
                    <br />
                    <br />
                  </HashLink>
                </li>
              </ul>
            </div>
            <div className="col-auto">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <HashLink
                    className="nav-link"
                    to="/posts#inicio-publicacoes"
                    style={{ color: "white", fontSize: "15px" }}
                  >
                    {t("menu.publicacoes")}
                  </HashLink>
                </li>
              </ul>
            </div>
            <div className="col-auto">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <HashLink
                    className="nav-link"
                    to="/oportunidade#trabalhe-conosco"
                    style={{ color: "white", fontSize: "15px" }}
                  >
                    {t("menu.oportunidades")}
                  </HashLink>
                </li>
              </ul>
            </div>
            <div className="col-auto">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <HashLink
                    className="nav-link"
                    to="/home#contacts-section"
                    style={{ color: "white", fontSize: "15px" }}
                  >
                    {t("menu.contato")}
                  </HashLink>
                </li>
              </ul>
            </div>
          </div>
        )}

        <div className="row py-4">
          <div className="col-12 text-center social-icons">
            <a href="https://www.linkedin.com/company/fitec/mycompany/">
              <img src={linkedin} alt="" className="img-fluid" />
            </a>
            <a href="https://www.youtube.com/channel/UCBI2_0oM8C8TaAIycOfCWnw/">
              <img src={youtube} alt="" className="img-fluid" />
            </a>
          </div>
        </div>
        <div className="row my-4 justify-content-center">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
            }}
          >
            <img src={logoFooter} alt="" class="img-fluid logo-footer" />
            <img src={seloISO} alt="" class="img-fluid logo-iso" />
          </div>
          <div className="col-12 text-center">
            <p className="copy mt-5" style={{ textAlign: "center" }}>
              &copy; {t("footer.1")}
              <br />
              <a
                href={privacidade}
                target="_blank"
                rel="noreferrer"
                style={{ color: "white" }}
              >
                {t("footer.2")}
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
