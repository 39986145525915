import React from 'react'

function CompanyLogo({src, alt, width}) {
  return (
    <div style={{ height: "70px", width: width <= 1223 ? 155 : 220, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <img src={src} alt={alt} style={{ width: width <= 1223 ? 135 : 200 }} />
          </div>
        </div>
  )
}

export default CompanyLogo