import ImageAndTitle from "../../components/ImageAndTitle";
import FitecPresenca from "../../assets/images/fitec-presenca.png";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../components/UseViewDimensions/useViewDimensions";

import sustentabilidade from "../../assets/images/img_sustentabilidade.jpg";
import responsabilidade from "../../assets/images/img_responsabilidadesocial.jpg";
import governanca from "../../assets/images/img_governanca.jpg";
import sobreFitec from "../../assets/images/img_banner__fitec.jpg";

function AboutFitec() {
  const isMobile = useMediaQuery({ query: "(max-width: 960px)" });
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  return (
    <>
      <ImageAndTitle
        id={"imagem-a-fitec"}
        img={sobreFitec}
        content={t("Fitec.1")}
      />

      <section className="section-sub-page bg-cinza-1" id="sobre">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2
                style={{
                  justifyContent: "center",
                  fontSize: width <= 700 ? "26px" : "",
                }}
              >
                {t("sobre.sobre")}
              </h2>
              <div className="row my-4 justify-content-center">
                {/*                 {!isMobile
                  ? <div className="col-8" style={{ "marginLeft": "200px" }}>
                    <div className="embed-responsive embed-responsive-16by9">
                      <iframe className="embed-responsive-item" width="600" height="337.5"
                        src="https://www.youtube.com/embed/CoK_BvaTcFw"
                        title="FITEC"
                        frameborder="0"
                        alt="Vídeo Institucional da FiTec com pessoas trabalhando em seus computadores"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;
                                            allowfullscreen">
                      </iframe>
                    </div>
                  </div>
                  : <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <iframe
                        style={{ display: "flex" }}
                        width="100%"
                        height="237.5"
                        src="https://www.youtube.com/embed/CoK_BvaTcFw" title="FITEC"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;
                                        allowfullscreen">
                      </iframe>
                    </div>
                  </div>} */}
              </div>
              <p style={{ padding: "10px" }}>
                {t("sobre.text-1.1")}
                <br />
                <br />
                {t("sobre.text-1.2")}
                <br />
                <br />
                {t("sobre.text-1.3")}
                <br />
                <br />
                {t("sobre.text-1.4")}
                <br />
                <br />
                {t("sobre.text-1.5")}
              </p>
            </div>
          </div>

          {!isMobile ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "300px",
                justifyContent: "space-evenly",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  style={{
                    background: "#004e77",
                    height: "130px",
                    width: "130px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    borderRadius: "50%",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "12px",
                      textAlign: "center",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {t("sobre.governanca")}
                  </h6>
                </div>
                <div
                  style={{
                    background: "#004e77",
                    height: "130px",
                    width: "130px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    borderRadius: "50%",
                  }}
                >
                  <h6
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "12px",
                      color: "white",
                    }}
                  >
                    {t("sobre.social")}
                  </h6>
                </div>
                <div
                  style={{
                    background: "#004e77",
                    height: "130px",
                    width: "130px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    borderRadius: "50%",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "12px",
                      textAlign: "center",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {t("sobre.sustentabilidade")}
                  </h6>
                </div>
                <div
                  style={{
                    background: "#004e77",
                    height: "130px",
                    width: "130px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    borderRadius: "50%",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "12px",
                      textAlign: "center",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {t("sobre.presenca")}
                  </h6>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "300px",
                justifyContent: "space-evenly",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  style={{
                    background: "#004e77",
                    height: "130px",
                    width: "130px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    borderRadius: "50%",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {t("sobre.governanca")}
                  </h6>
                </div>
                <div
                  style={{
                    background: "#004e77",
                    height: "130px",
                    width: "130px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    borderRadius: "50%",
                  }}
                >
                  <h6
                    style={{
                      textAlign: "center",
                      fontSize: "12px",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {t("sobre.social")}
                  </h6>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  style={{
                    background: "#004e77",
                    height: "130px",
                    width: "130px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    borderRadius: "50%",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {t("sobre.sustentabilidade")}
                  </h6>
                </div>
                <div
                  style={{
                    background: "#004e77",
                    height: "130px",
                    width: "130px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    borderRadius: "50%",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {t("sobre.presenca")}
                  </h6>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      <ImageAndTitle img={governanca} />
      <section className="section-sub-page" id="governanca">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2
                id="governanca"
                style={{
                  justifyContent: "center",
                  fontSize: width <= 700 ? "26px" : "",
                }}
              >
                {t("sobre.governanca")}
              </h2>
              <p style={{ padding: "15px" }}>{t("sobre.text-2.1")}</p>
              {!isMobile ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "300px",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div
                      style={{
                        background: "#004e77",
                        height: "140px",
                        width: "140px",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        borderRadius: "50%",
                      }}
                    >
                      <h6
                        style={{
                          fontSize: "12px",
                          textAlign: "center",
                          fontWeight: "bold",
                          color: "white",
                        }}
                      >
                        CONSELHO CURADOR
                      </h6>
                    </div>
                    <div
                      style={{
                        background: "#004e77",
                        height: "140px",
                        width: "140px",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        borderRadius: "50%",
                      }}
                    >
                      <h6
                        style={{
                          fontSize: "12px",
                          textAlign: "center",
                          fontWeight: "bold",
                          color: "white",
                        }}
                      >
                        DIRETORIA EXECUTIVA
                      </h6>
                    </div>
                    <div
                      style={{
                        background: "#004e77",
                        height: "140px",
                        width: "140px",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        borderRadius: "50%",
                      }}
                    >
                      <h6
                        style={{
                          fontSize: "12px",
                          textAlign: "center",
                          fontWeight: "bold",
                          color: "white",
                        }}
                      >
                        CONSELHO FISCAL
                      </h6>
                    </div>
                    <div
                      style={{
                        background: "#004e77",
                        height: "140px",
                        width: "140px",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        borderRadius: "50%",
                      }}
                    >
                      <h6
                        style={{
                          fontSize: "12px",
                          textAlign: "center",
                          fontWeight: "bold",
                          color: "white",
                        }}
                      >
                        CONSELHO TÉCNICO CIENTÍFICO
                      </h6>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "300px",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div
                      style={{
                        background: "#004e77",
                        height: "140px",
                        width: "140px",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        borderRadius: "50%",
                      }}
                    >
                      <h6
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          color: "white",
                        }}
                      >
                        CONSELHO CURADOR
                      </h6>
                    </div>
                    <div
                      style={{
                        background: "#004e77",
                        height: "140px",
                        width: "140px",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        borderRadius: "50%",
                      }}
                    >
                      <h6
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          fontWeight: "bold",
                          color: "white",
                        }}
                      >
                        DIRETORIA EXECUTIVA
                      </h6>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div
                      style={{
                        background: "#004e77",
                        height: "140px",
                        width: "140px",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        borderRadius: "50%",
                      }}
                    >
                      <h6
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          color: "white",
                        }}
                      >
                        CONSELHO FISCAL
                      </h6>
                    </div>
                    <div
                      style={{
                        background: "#004e77",
                        height: "140px",
                        width: "140px",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        borderRadius: "50%",
                      }}
                    >
                      <h6
                        style={{
                          fontSize: "12px",
                          textAlign: "center",
                          fontWeight: "bold",
                          color: "white",
                        }}
                      >
                        CONSELHO TÉCNICO CIENTÍFICO
                      </h6>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="row mt-5" style={{ padding: "15px" }}>
            <div className="col-sm-4">
              <h3>{t("sobre.text-2.2")}</h3>
              <p>{t("sobre.text-2.3")}</p>
            </div>
            <div className="col-sm-4">
              <h3>{t("sobre.text-2.4")}</h3>
              <p>{t("sobre.text-2.5")}</p>
            </div>
            <div className="col-sm-4">
              <h3>{t("sobre.text-2.6")}</h3>
              <p>{t("sobre.text-2.7")}</p>
            </div>
          </div>
        </div>
      </section>
      <ImageAndTitle img={responsabilidade} />
      <section
        className="section-sub-page bg-cinza-1"
        id="responsabilidade-social"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2
                id="responsabilidade-social"
                style={{
                  justifyContent: "center",
                  fontSize: width <= 700 ? "26px" : "",
                }}
              >
                {t("sobre.social")}
              </h2>

              <p style={{ padding: "15px" }}>
                {t("sobre.text-3.1")}
                <br />
                <br />
                {t("sobre.text-3.2")}
              </p>
            </div>
          </div>
        </div>
      </section>
      <ImageAndTitle img={sustentabilidade} alt="muda de planta" />
      <section className="section-sub-page" id="sustentabilidade">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2
                id="sustentabilidade"
                style={{
                  justifyContent: "center",
                  fontSize: width <= 700 ? "26px" : "",
                }}
              >
                {t("sobre.sustentabilidade")}
              </h2>
              <p style={{ padding: "15px" }}>
                {t("sobre.text-4.1")}
                <br />
                <br />
                {t("sobre.text-4.2")}
                <br />
                <br />
                {t("sobre.text-4.3")}
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            justifySelf: "center",
          }}
        >
          {!isMobile ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              <div className="ctn-rounded bg-cinza-1" style={{ width: "20%" }}>
                <p style={{ textAlign: "center" }}>{t("sobre.text-4.4")}</p>
              </div>

              <div className="ctn-rounded bg-cinza-1" style={{ width: "20%" }}>
                <p style={{ textAlign: "center" }}>{t("sobre.text-4.5")}</p>
              </div>

              <div className="ctn-rounded bg-cinza-1" style={{ width: "20%" }}>
                <p style={{ textAlign: "center" }}>{t("sobre.text-4.6")}</p>
              </div>

              <div className="ctn-rounded bg-cinza-1" style={{ width: "20%" }}>
                <p style={{ textAlign: "center" }}>{t("sobre.text-4.7")}</p>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                height: "260px",
              }}
            >
              <div>
                <div
                  className="ctn-rounded bg-cinza-1"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: width <= 700 ? "150px" : "180px",
                    height: width <= 700 ? "250px" : "215px",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    {t("sobre.text-4.4")}
                  </p>
                </div>
              </div>
              <div>
                <div
                  className="ctn-rounded bg-cinza-1"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: width <= 700 ? "150px" : "180px",
                    height: width <= 700 ? "250px" : "215px",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      paddingTop: "10px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    {t("sobre.text-4.5")}
                  </p>
                </div>
              </div>
            </div>
          )}

          {isMobile ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              <div>
                <div
                  className="ctn-rounded bg-cinza-1"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: width <= 700 ? "150px" : "180px",
                    height: width <= 700 ? "250px" : "215px",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      paddingTop: "30px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    {t("sobre.text-4.6")}
                  </p>
                </div>
              </div>
              <div>
                <div
                  className="ctn-rounded bg-cinza-1"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: width <= 700 ? "150px" : "180px",
                    height: width <= 700 ? "250px" : "215px",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      paddingTop: "20px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    {t("sobre.text-4.7")}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </section>

      <section className="section-contato section-presenca" id="contato">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2
                style={{
                  justifyContent: "center",
                  fontSize: width <= 700 ? "26px" : "",
                }}
                className="wow fadeIn"
                data-wow-delay="0.4s"
              >
                {t("sobre.presenca")}
              </h2>
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-sm-6">
              <img
                src={FitecPresenca}
                alt="Mapa do Brasil com os estados destacados da sede e filiais da FiTec"
                className="img-fluid wow fadeInDown"
                data-wow-delay="0.2s"
              />
            </div>
            <div className="col-sm-4">
              <h3 className="wow fadeInDown" data-wow-delay="0.2s">
                Recife - PE
              </h3>
              <h3 className="wow fadeInDown" data-wow-delay="0.4s">
                Campinas - SP
              </h3>
              <h3 className="wow fadeInDown" data-wow-delay="0.6s">
                São Jose dos Campos - SP
              </h3>
              <h3 className="wow fadeInDown" data-wow-delay="0.8s">
                Belo Horizonte - MG
              </h3>
              <h3 className="wow fadeInDown" data-wow-delay="0.8s">
                Manaus - AM
              </h3>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutFitec;
