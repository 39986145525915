import React, { useEffect, useState, useRef } from "react";
import videoMp4 from "../../assets/video/video.mp4";
import videoWebm from "../../assets/video/video.webm";
import background from "../../assets/images/img_backgroundAtuacao.jpg";
import "../css/main.css";
import "../css/animate.css";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

export default function IntroHome() {
  const isHd = useMediaQuery({ query: "(max-width: 1224px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const { t } = useTranslation();
  const [device, setDevice] = useState("not");

  const videoRef = useRef(null);

  useEffect(() => {
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      setDevice("iPhone");
    } else {
      setDevice("not");
    }
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
      scaleVideoContainer();

      initBannerVideoSize(".video-container .poster img");
      initBannerVideoSize(".video-container .filter");
      initBannerVideoSize(".video-container video");

      window.addEventListener("resize", handleResize);

      // Cleanup on unmount
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }

    function handleResize() {
      scaleVideoContainer();
      scaleBannerVideoSize(".video-container .poster img");
      scaleBannerVideoSize(".video-container .filter");
      scaleBannerVideoSize(".video-container video");
    }

    function scaleVideoContainer() {
      const height = window.innerHeight + 5;
      const unitHeight = `${height}px`;
      document.querySelector(".homepage-hero-module").style.height = unitHeight;
    }

    function initBannerVideoSize(element) {
      document.querySelectorAll(element).forEach((el) => {
        el.dataset.height = el.offsetHeight;
        el.dataset.width = el.offsetWidth;
      });
      scaleBannerVideoSize(element);
    }

    function scaleBannerVideoSize(element) {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight + 5;

      document.querySelectorAll(element).forEach((el) => {
        const videoAspectRatio = el.dataset.height / el.dataset.width;
        el.style.width = `${windowWidth}px`;

        if (windowWidth < 1000) {
          const videoHeight = windowHeight;
          const videoWidth = videoHeight / videoAspectRatio;
          el.style.marginTop = "0px";
          el.style.marginLeft = `${-(videoWidth - windowWidth) / 2}px`;
          el.style.width = `${videoWidth}px`;
          el.style.height = `${videoHeight}px`;
        }

        el.classList.add("fadeIn", "animated");
      });
    }
  }, []);

  return (
    <section
      style={{
        paddingTop: 0,
        paddingBottom: 0,
        height: isMobile ? 660 : isHd ? 630 : 755,
      }}
      id="main-banner-section"
    >
      <div style={{ height: isMobile ? 660 : isHd ? 630 : 755 }}>
        {device === "not" ? (
          <div className="video-container">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                backgroundColor: "rgba(0, 0, 0, 0.4)",
                zIndex: 2,
                height: isMobile ? 660 : isHd ? 630 : 755,
                backgroundImage:
                  "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADAQMAAABs5if8AAAAA3NCSVQICAjb4U/gAAAABlBMVEUAAAD///+l2Z/dAAAAAnRSTlP/AOW3MEoAAAAJcEhZcwAAAuwAAALsAe0ztPoAAAAWdEVYdENyZWF0aW9uIFRpbWUAMDQvMTMvMTGrW0T6AAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M1cbXjNgAAAA5JREFUCJljSGBYwHAAAASGAcENz5GAAAAAAElFTkSuQmCC)",
              }}
            >
              <div
                className="content-home"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  width: isMobile ? "100%" : isHd ? "40%" : "40%",
                }}
              >
                <h1
                  className="wow fadeInDown"
                  data-wow-delay="0.4s"
                  style={{ zIndex: 3 }}
                >
                  {t("home.intro-home.1")}
                  <span>
                    <br />
                    {t("home.intro-home.2")}
                  </span>
                </h1>
                <p
                  className="wow fadeInDown"
                  data-wow-delay="0.6s"
                  style={{ zIndex: 3 }}
                >
                  {t("home.intro-home.3")}
                  <br />
                  {t("home.intro-home.4")}
                </p>
              </div>
            </div>

            <video
              autoPlay="autoplay"
              loop
              className="fillWidth"
              muted
              id="video"
              style={{
                position: "absolute",
                zIndex: 1,
              }}
            >
              <source src={videoMp4} type="video/mp4" />
              <source src={videoWebm} type="video/webm" />
            </video>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "stretch",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                backgroundColor: "rgba(0, 0, 0, 0.4)",
                zIndex: 2,
                height: isMobile ? 620 : isHd ? 630 : 755,
                backgroundImage:
                  "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADAQMAAABs5if8AAAAA3NCSVQICAjb4U/gAAAABlBMVEUAAAD///+l2Z/dAAAAAnRSTlP/AOW3MEoAAAAJcEhZcwAAAuwAAALsAe0ztPoAAAAWdEVYdENyZWF0aW9uIFRpbWUAMDQvMTMvMTGrW0T6AAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M1cbXjNgAAAA5JREFUCJljSGBYwHAAAASGAcENz5GAAAAAAElFTkSuQmCC)",
              }}
            >
              <div
                className="content-home"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  width: isMobile ? "100%" : isHd ? "40%" : "40%",
                }}
              >
                <h1
                  className="wow fadeInDown"
                  data-wow-delay="0.4s"
                  style={{ zIndex: 3 }}
                >
                  {t("home.intro-home.1")}
                  <span>
                    <br />
                    {t("home.intro-home.2")}
                  </span>
                </h1>
                <p
                  className="wow fadeInDown"
                  data-wow-delay="0.6s"
                  style={{ zIndex: 3 }}
                >
                  {t("home.intro-home.3")}
                  <br />
                  {t("home.intro-home.4")}
                </p>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                zIndex: 1,
                width: "100%",
                height: isMobile ? 620 : isHd ? 630 : 755,
                overflow: "hidden",
              }}
            >
              <img
                src={background}
                alt="background"
                width="isMobile ? 100% : isHd ? 630 : 110%"
              />
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
