import React from "react";
import useWindowDimensions from "../../../components/UseViewDimensions/useViewDimensions";
import "../../css/main.css";

import alfamed from "../../../assets/images/cliente/alfamed.png";
import algar from "../../../assets/images/cliente/algar.png";
import bosch from "../../../assets/images/cliente/bosch.png";
import celpa from "../../../assets/images/cliente/celpa.png";
import cemar from "../../../assets/images/cliente/cemar.png";

import cemig from "../../../assets/images/cliente/cemigColor.png";
import chesf from "../../../assets/images/cliente/chesfColor.png";
import ciena from "../../../assets/images/cliente/ciena.png";
import cisco from "../../../assets/images/cliente/cisco.png";
import clamper from "../../../assets/images/cliente/clamper.png";

import claro from "../../../assets/images/cliente/claro.png";
import cm from "../../../assets/images/cliente/cm.png";
import constanta from "../../../assets/images/cliente/constanta.png";
import dpr from "../../../assets/images/cliente/dpr.png";
import elektro from "../../../assets/images/cliente/elektro.png";

import eletrobras from "../../../assets/images/cliente/eletrobras.png";
import eletro_paulo from "../../../assets/images/cliente/eletropaulo.png";
import enel from "../../../assets/images/cliente/enel.png";
import energisa from "../../../assets/images/cliente/energisa.png";
import equatorial from "../../../assets/images/cliente/equatorial02.png";

import equatorial_energia from "../../../assets/images/cliente/equatorial.png";
import ericsson from "../../../assets/images/cliente/ericssonColor.png";
import foxconn from "../../../assets/images/cliente/Foxconn-logo.png";
import furukawa from "../../../assets/images/cliente/furukawa.png";
import ge from "../../../assets/images/cliente/ge.png";

import huawei from "../../../assets/images/cliente/huaweiColor.png";
import iberola from "../../../assets/images/cliente/iberdola.png";
import ingeteam from "../../../assets/images/cliente/ingeteam.png";
import multilaser from "../../../assets/images/cliente/multilaser.png";
import nokia from "../../../assets/images/cliente/nokia.png";

import oi from "../../../assets/images/cliente/oi.png";
import philips from "../../../assets/images/cliente/philips.png";
import positivo from "../../../assets/images/cliente/positivo.png";
import sap from "../../../assets/images/cliente/sap.png";
import senai from "../../../assets/images/cliente/senaiColor.png";

import siemens from "../../../assets/images/cliente/siemens.png";
import tim from "../../../assets/images/cliente/tim.png";
import tury from "../../../assets/images/cliente/tury.png";
import vivo from "../../../assets/images/cliente/vivo.png";
import vmi from "../../../assets/images/cliente/vmi_medica.png";

import vmi_security from "../../../assets/images/cliente/vmi_security.png";
import lacerda from "../../../assets/images/cliente/lacerda.png";
import vyaire from "../../../assets/images/cliente/vyaire.png";
import CompanyLogo from "../../CompanyLogo";

export default function SectionBox() {
  const { width } = useWindowDimensions();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "center",
        height: "1000px",
        overflow: "hidden",
      }}
    >
      <div
        id="item"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        <CompanyLogo src={alfamed} alt="Alfamed" width={width} />
        <CompanyLogo src={algar} alt="Algar" width={width} />
        <CompanyLogo src={bosch} alt="Bosch" width={width} />
        <CompanyLogo src={celpa} alt="Celpa" width={width} />
        <CompanyLogo src={cemar} alt="Cemar" width={width} />
        <CompanyLogo src={cemig} alt="Cemig" width={width} />
      </div>

      <div
        id="item"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        <CompanyLogo src={chesf} alt="Chesf" width={width} />
        <CompanyLogo src={ciena} alt="Ciena" width={width} />
        <CompanyLogo src={cisco} alt="Cisco" width={width} />
        <CompanyLogo src={clamper} alt="Clamper" width={width} />
        <CompanyLogo src={claro} alt="Claro" width={width} />
        <CompanyLogo src={cm} alt="CM Comandos" width={width} />
      </div>

      <div
        id="item"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <CompanyLogo src={constanta} alt="Constanta" width={width} />
        <CompanyLogo src={dpr} alt="DPR" width={width} />
        <CompanyLogo src={elektro} alt="Elektro" width={width} />
        <CompanyLogo src={eletrobras} alt="Eletrobras" width={width} />
        <CompanyLogo src={eletro_paulo} alt="Eletropaulo" width={width} />
        <CompanyLogo src={enel} alt="Enel" width={width} />
      </div>

      <div
        id="item"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <CompanyLogo src={energisa} alt="Energisa" width={width} />
        <CompanyLogo src={equatorial} alt="Equatorial" width={width} />
        <CompanyLogo
          src={equatorial_energia}
          alt="Equatorial Energia"
          width={width}
        />
        <CompanyLogo src={ericsson} alt="Ericsson" width={width} />
        <CompanyLogo src={foxconn} alt="Foxconn" width={width} />
        <CompanyLogo src={furukawa} alt="Furukawa" width={width} />
      </div>

      <div
        id="item"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <CompanyLogo src={ge} alt="GE Healthcare" width={width} />
        <CompanyLogo src={huawei} alt="Huawei" width={width} />
        <CompanyLogo src={iberola} alt="Iberdola" width={width} />
        <CompanyLogo src={ingeteam} alt="Ingeteam" width={width} />
        <CompanyLogo src={lacerda} alt="Lacerda" width={width} />
        <CompanyLogo src={multilaser} alt="Multilaser" width={width} />
      </div>

      <div
        id="item"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <CompanyLogo src={nokia} alt="Nokia" width={width} />
        <CompanyLogo src={oi} alt="OI" width={width} />
        <CompanyLogo src={philips} alt="Philips" width={width} />
        <CompanyLogo src={positivo} alt="Positivo" width={width} />
        <CompanyLogo src={sap} alt="Sap" width={width} />
        <CompanyLogo src={senai} alt="Senai" width={width} />
      </div>

      <div
        id="item"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <CompanyLogo src={siemens} alt="Siemens" width={width} />
        <CompanyLogo src={tim} alt="Tim" width={width} />
        <CompanyLogo src={tury} alt="Tury" width={width} />
        <CompanyLogo src={vivo} alt="Vivo" width={width} />
        <CompanyLogo src={vmi} alt="VMI Medica" width={width} />
        <CompanyLogo src={vmi_security} alt="VMI Security" width={width} />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <CompanyLogo src={vyaire} alt="Vyaire" width={width} />
      </div>
    </div>
  );
}
