import React from "react";
import "../../css/main.css";
import "../style.css";

import alfamed from "../../../assets/images/cliente/alfamed.png";
import algar from "../../../assets/images/cliente/algar.png";
import bosch from "../../../assets/images/cliente/bosch.png";
import celpa from "../../../assets/images/cliente/celpa.png";
import cemar from "../../../assets/images/cliente/cemar.png";

import cemig from "../../../assets/images/cliente/cemigColor.png";
import chesf from "../../../assets/images/cliente/chesfColor.png";
import ciena from "../../../assets/images/cliente/ciena.png";
import cisco from "../../../assets/images/cliente/cisco.png";
import clamper from "../../../assets/images/cliente/clamper.png";

import claro from "../../../assets/images/cliente/claro.png";
import cm from "../../../assets/images/cliente/cm.png";
import constanta from "../../../assets/images/cliente/constanta.png";
import dpr from "../../../assets/images/cliente/dpr.png";
import elektro from "../../../assets/images/cliente/elektro.png";

import eletrobras from "../../../assets/images/cliente/eletrobras.png";
import eletro_paulo from "../../../assets/images/cliente/eletropaulo.png";
import enel from "../../../assets/images/cliente/enel.png";
import energisa from "../../../assets/images/cliente/energisa.png";
import equatorial from "../../../assets/images/cliente/equatorial02.png";

import equatorial_energia from "../../../assets/images/cliente/equatorial.png";
import ericsson from "../../../assets/images/cliente/ericssonColor.png";
import foxconn from "../../../assets/images/cliente/Foxconn-logo.png";
import furukawa from "../../../assets/images/cliente/furukawa.png";
import ge from "../../../assets/images/cliente/ge.png";

import huawei from "../../../assets/images/cliente/huaweiColor.png";
import iberola from "../../../assets/images/cliente/iberdola.png";
import ingeteam from "../../../assets/images/cliente/ingeteam.png";
import multilaser from "../../../assets/images/cliente/multilaser.png";
import nokia from "../../../assets/images/cliente/nokia.png";

import oi from "../../../assets/images/cliente/oi.png";
import philips from "../../../assets/images/cliente/philips.png";
import positivo from "../../../assets/images/cliente/positivo.png";
import sap from "../../../assets/images/cliente/sap.png";
import senai from "../../../assets/images/cliente/senaiColor.png";

import siemens from "../../../assets/images/cliente/siemens.png";
import tim from "../../../assets/images/cliente/tim.png";
import tury from "../../../assets/images/cliente/tury.png";
import vivo from "../../../assets/images/cliente/vivo.png";
import vmi from "../../../assets/images/cliente/vmi_medica.png";

import vmi_security from "../../../assets/images/cliente/vmi_security.png";
import lacerda from "../../../assets/images/cliente/lacerda.png";
import vyaire from "../../../assets/images/cliente/vyaire.png";

export default function SectionBoxResponsive() {
  return (
    <div id="carousel-client" className="carousel" data-ride="carousel">
      <ol className="carousel-indicators" style={{ bottom: -80 }}>
        <li
          data-target="#carousel-client"
          data-slide-to="0"
          className="active"
        ></li>
        <li data-target="#carousel-client" data-slide-to="1"></li>
        <li data-target="#carousel-client" data-slide-to="2"></li>
        <li data-target="#carousel-client" data-slide-to="3"></li>
        <li data-target="#carousel-client" data-slide-to="4"></li>
        <li data-target="#carousel-client" data-slide-to="5"></li>
        <li data-target="#carousel-client" data-slide-to="6"></li>
        <li data-target="#carousel-client" data-slide-to="7"></li>
      </ol>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <div
            className="row mt-3"
            style={{ paddingLeft: "25px", paddingRight: "25px", gap: "10px" }}
          >
            <div
              className="col"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={alfamed}
                alt="Alfamed"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={algar}
                alt="Algar"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={bosch}
                alt="Bosch"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={celpa}
                alt="Celpa"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={cemar}
                alt="Cemar"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={cemig}
                alt="Cemig"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
          </div>
        </div>

        <div className="carousel-item">
          <div
            className="row mt-3"
            style={{ paddingLeft: "25px", paddingRight: "25px", gap: "10px" }}
          >
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={chesf}
                alt="Chesf"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={ciena}
                alt="Ciena"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={cisco}
                alt="Cisco"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={clamper}
                alt="Clamper"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={claro}
                alt="Claro"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={cm}
                alt="CM Comandos"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
          </div>
        </div>

        <div className="carousel-item">
          <div
            className="row mt-3"
            style={{ paddingLeft: "25px", paddingRight: "25px", gap: "10px" }}
          >
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={constanta}
                alt="Costanta"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={dpr}
                alt="DPR"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={elektro}
                alt="Elektro"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={eletrobras}
                alt="Eletrobras"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={eletro_paulo}
                alt="Eletropaulo"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={enel}
                alt="Enel"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
          </div>
        </div>

        <div className="carousel-item">
          <div
            className="row mt-3"
            style={{ paddingLeft: "25px", paddingRight: "25px", gap: "10px" }}
          >
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={energisa}
                alt="Energisa"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={equatorial}
                alt="Equatorial"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={equatorial_energia}
                alt="Equatorial"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={ericsson}
                alt="Ericsson"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={foxconn}
                alt="Foxconn"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={furukawa}
                alt="Furukawa"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
          </div>
        </div>

        <div className="carousel-item">
          <div
            className="row mt-3"
            style={{ paddingLeft: "25px", paddingRight: "25px", gap: "10px" }}
          >
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={ge}
                alt="GE Healthcare"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={huawei}
                alt="Huawei"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={iberola}
                alt="Iberdola"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={ingeteam}
                alt="Ingeteam"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={lacerda}
                alt="Lacerda"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={multilaser}
                alt="Multilaser"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
          </div>
        </div>

        <div className="carousel-item">
          <div
            className="row mt-3"
            style={{ paddingLeft: "25px", paddingRight: "25px", gap: "10px" }}
          >
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={nokia}
                alt="Nokia"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={oi}
                alt="OI"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={philips}
                alt="Philips"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={positivo}
                alt="Positivo"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={sap}
                alt="SAP"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={senai}
                alt="Senai"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
          </div>
        </div>

        <div className="carousel-item">
          <div
            className="row mt-3"
            style={{ paddingLeft: "25px", paddingRight: "25px", gap: "10px" }}
          >
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={siemens}
                alt="Siemens"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={tim}
                alt="TIM"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={tury}
                alt="Tury"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={vivo}
                alt="VIVO"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
          </div>
        </div>

        <div className="carousel-item">
          <div
            className="row mt-3"
            style={{ paddingLeft: "25px", paddingRight: "25px", gap: "10px" }}
          >
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={vmi}
                alt="VMI Medica"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={vmi_security}
                alt="VMI Security"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
            <div
              className="col"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={vyaire}
                alt="Vyaire"
                className="mx-auto"
                style={{ width: 180, height: 65 }}
              />
            </div>
          </div>
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-target="#carousel-client"
        data-slide="prev"
        style={{ paddingRight: "35px" }}
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-target="#carousel-client"
        data-slide="next"
        style={{ paddingLeft: "35px" }}
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </button>
    </div>
  );
}
