import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import privacidade from "../../assets/pdf/privacidade.pdf";
import "../css/main.css";

export default function SectionOportunidades() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 1080px)" });
  var widthMedium = "(max-width: 960px)" ? "40%" : "65%";
  const buttonStyle = {
    marginLeft: !isMobile ? "30%" : "15%",
    display: "flex",
    width: isMobile ? "75%" : widthMedium,
    alignContent: "center",
    justifyContent: "center",
  };

  return (
    <section
      className="section-oportunidades pb-0"
      id="opportunities-section"
      style={{ paddingTop: !isMobile ? "180px" : "90px" }}
    >
      <div className="container">
        <div className="row">
          <div className="col">
            <h2 className="wow fadeIn" data-wow-delay="0.4s">
              {t("contato.oportunidades")}
            </h2>
          </div>
        </div>
      </div>
      <div className="bg-oportunidades">
        <div className="container">
          <div className="row">
            <div
              className="col-12"
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <p className="wow fadeInLeft" data-wow-delay="0.4s">
                {t("contato.text-1.1")}
                <br />
                <br />
                {t("contato.text-1.2")}
                <br />
                <br />
                {t("contato.text-1.3")}{" "}
                <a href="https://fitec.gupy.io/">https://fitec.gupy.io/</a>.
                {t("contato.text-1.4")}
                <a href={privacidade} target="_blank" rel="noreferrer">
                  {t("footer.2")}
                </a>{" "}
                {t("contato.text-1.5")}
                <a href={privacidade} target="_blank" rel="noreferrer">
                  {t("footer.2")}
                </a>{" "}
                {t("contato.text-1.6")}
                <br />
                <br />
                {t("contato.text-1.8")}
              </p>

              <hr />

              <a
                className="link animation wow fadeInLeft"
                href="https://fitec.gupy.io/"
                target="_blank"
                rel="noreferrer"
                data-wow-delay="0.4s"
                style={buttonStyle}
              >
                {t("home.vagas")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
