import './index.css'

function ContactUs() {
    function onEmailClick() {
      var message;

      if(document.getElementById("name").value !== '' && 
         document.getElementById("phone").value !== '' && 
         document.getElementById("email").value !== '' && 
         document.getElementById("message").value !== '') {

        message = 'Olá, me chamo ' + document.getElementById("name").value + ',\n' + 
        'Meu telefone para contato é: ' + document.getElementById("phone").value + 
        ', e meu e-mail: ' + document.getElementById("email").value + '.\n' + 
        document.getElementById("message").value;

        window.open(document.location.href = "mailto:carlostarabal@fitec.org.br?subject="
        + encodeURIComponent(document.getElementById("subject").value)
        + "&body=" + encodeURIComponent(message));
      }
    }

    return (
      <>
       <section className="contact-page-section">
        <div className="container">
          <div className="sec-title">
            <div className="title">Contate-nos</div>
            <h2>Fale conosco</h2>
          </div>
          <div className="inner-container">
            <div className="row clearfix">
              {/*Form Column*/}
              <div className="form-column col-md-8 col-sm-12 col-xs-12">
                <div className="inner-column">
                  {/*Contact Form*/}
                  <div className="contact-form">
                    <form id="contact-form">
                      <div className="row clearfix">
                        <div className="form-group col-md-6 col-sm-6 co-xs-12">
                          <input id='name' type="text" name="name" placeholder="Nome" required />
                        </div>
                        <div className="form-group col-md-6 col-sm-6 co-xs-12">
                          <input id='email' type="email" name="email" placeholder="Email" required />
                        </div>
                        <div className="form-group col-md-6 col-sm-6 co-xs-12">
                          <input id='subject' type="text" name="subject" placeholder="Assunto" required />
                        </div>
                        <div className="form-group col-md-6 col-sm-6 co-xs-12">
                          <input id='phone' type="text" name="phone" placeholder="Telefone" required />
                        </div>
                        <div className="form-group col-md-12 col-sm-12 co-xs-12">
                          <textarea id='message' name="message" placeholder="Mensagem" defaultValue={""} />
                        </div>
                        <div className="form-group col-md-12 col-sm-12 co-xs-12">
                          <button onClick={onEmailClick}type="submit" className="theme-btn btn-style-one">Enviar</button>
                        </div>
                      </div>
                    </form>
                  </div>
                  {/*End Contact Form*/}
                </div>
              </div>
              {/*Info Column*/}
              <div className="info-column col-md-4 col-sm-12 col-xs-12">
                <div className="inner-column">
                  <h2>Contato</h2>
                  <ul className="list-info">
                    <li><i className="fas fa-globe" />Rua Madre de Deus, 27 – 16º andar. Bairro Recife - CEP: 50030-906 – Recife, PE.</li>
                    <li><i className="fas fa-phone" />Fone: (19) 2137-6803 <br /><a href='https://wa.me/5519982084396' className='link'>Whatsapp:<br/> (19) 98208-4396</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </>
    )
}

export default ContactUs