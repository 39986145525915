import { useMediaQuery } from "react-responsive";


function ImageAndTitle({ img, alt, content, id }) {
  const isMobile = useMediaQuery({ query: "(max-width: 1224px)" })

  return (
    <div
      id={id}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        overflow: "hidden",
        position: 'relative'
      }}>
      <img src={img} alt={alt} width='100%' />
      <h1 style={{ position: 'absolute', color: '#fff', fontSize: isMobile ? '36px' : '48px', fontWeight: 'bold' }}>{content}</h1>
    </div>
  )
}

export default ImageAndTitle
