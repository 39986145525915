import React from "react";
import "../../components/css/main.css";
import logoJs from "../../assets/images/ambientes/programacao/logo_js.svg";
import logoNodeJs from "../../assets/images/ambientes/programacao/logo_nodejs.svg";
import logoPython from "../../assets/images/ambientes/programacao/logo_python.svg";
import logoAngular from "../../assets/images/ambientes/programacao/logo_angular.svg";
import logoCss from "../../assets/images/ambientes/programacao/logo_css3.svg";
import logoKafka from "../../assets/images/ambientes/programacao/logo_kafka.svg";
import logoplus from "../../assets/images/ambientes/programacao/logo_c_plus.svg";

import logoMongo from "../../assets/images/ambientes/banco/logo_mongoDB.svg";
import logoPostgres from "../../assets/images/ambientes/banco/logo_postgresql.svg";
import logoSqlServer from "../../assets/images/ambientes/banco/logo_sqlserver.svg";
import logoOracle from "../../assets/images/ambientes/banco/logo_oracle.svg";

import logo_hadoop from "../../assets/images/ambientes/arquitetura/logo_apache_hadoop.svg";
import logo_apache from "../../assets/images/ambientes/arquitetura/logo_apache_hbase.svg";
import logo_apache_spark from "../../assets/images/ambientes/arquitetura/logo_apache_spark.svg";
import logo_aws from "../../assets/images/ambientes/arquitetura/logo_aws.svg";
import logo_azure from "../../assets/images/ambientes/arquitetura/logo_azure.svg";
import logo_google_cloud from "../../assets/images/ambientes/arquitetura/logo_google_cloud.svg";
import logo_redis from "../../assets/images/ambientes/arquitetura/logo_redis.svg";

import logo_docker from "../../assets/images/ambientes/container/logo_docker.svg";
import logo_kubernetes from "../../assets/images/ambientes/container/logo_kubernetes.svg";

import logo_jenkins from "../../assets/images/ambientes/automacao/logo_jenkins.svg";

import logo_android from "../../assets/images/ambientes/frameworks/logo_android.svg";
import logoFlutter from "../../assets/images/ambientes/frameworks/logo_flutter.svg";
import logo_react from "../../assets/images/ambientes/programacao/logo_react.svg";
import logo_react_native from "../../assets/images/ambientes/programacao/logo_react_native.svg";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import useWindowDimensions from "../UseViewDimensions/useViewDimensions";

export default function SectionAmbiente() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 790px)" });
  const { width } = useWindowDimensions();

  return (
    <section className="section-ambientes pt-0" id="ambient-section">
      <div className="container" style={{ backgroundColor: "white" }}>
        <div className="row">
          <div className="col">
            <h2 className="wow fadeIn" data-wow-delay="0.4s">
              {t("home.ambiente.title")}
            </h2>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-ambientes">
        {isMobile ? (
          <div id="carousel-ambient" className="carousel" data-ride="carousel">
            <ol className="carousel-indicators" style={{ bottom: -80 }}>
              <li
                data-target="#carousel-ambient"
                data-slide-to="0"
                className="active"
                style={{ backgroundColor: "rgb(255,255,255" }}
              ></li>
              <li
                data-target="#carousel-ambient"
                data-slide-to="1"
                style={{ backgroundColor: "rgb(255,255,255" }}
              ></li>
              <li
                data-target="#carousel-ambient"
                data-slide-to="2"
                style={{ backgroundColor: "rgb(255,255,255" }}
              ></li>
              <li
                data-target="#carousel-ambient"
                data-slide-to="3"
                style={{ backgroundColor: "rgb(255,255,255" }}
              ></li>
              <li
                data-target="#carousel-ambient"
                data-slide-to="4"
                style={{ backgroundColor: "rgb(255,255,255" }}
              ></li>
              <li
                data-target="#carousel-ambient"
                data-slide-to="5"
                style={{ backgroundColor: "rgb(255,255,255" }}
              ></li>
              <li
                data-target="#carousel-ambient"
                data-slide-to="6"
                style={{ backgroundColor: "rgb(255,255,255" }}
              ></li>
            </ol>
            <div className="carousel-inner">
              <div
                className="carousel-item active"
                style={{ height: 300, paddingTop: "40px" }}
              >
                <div
                  className="row mt-3"
                  id="item"
                  style={{
                    paddingLeft: width > 525 && width < 791 ? "20%" : "8%",
                    paddingRight: width > 525 && width < 791 ? "20%" : "12%",
                  }}
                >
                  <div className="col">
                    <div className="row mt-3" id="item">
                      <div className="col sm-2" style={{ width: 0 }}>
                        <img
                          src={logoJs}
                          alt="JavaScript"
                          className="mx-auto"
                          style={{ width: 150, height: 65, paddingTop: 5 }}
                        />
                      </div>
                      <div className="col sm-2" style={{ width: 0 }}>
                        <img
                          src={logoplus}
                          alt="C#"
                          className="mx-auto"
                          style={{ width: 120, height: 65, paddingTop: 10 }}
                        />
                      </div>
                      <div className="col sm-2" style={{ width: 50 }}>
                        <img
                          src={logoCss}
                          alt="CSS"
                          className="mx-auto"
                          style={{ width: 100, height: 65 }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3" id="item">
                    <div className="col sm-2" style={{ width: 50 }}>
                      <img
                        src={logoPython}
                        alt="Python"
                        className="mx-auto"
                        style={{ width: 150, height: 75 }}
                      />
                    </div>
                    <div className="col sm-2">
                      <img
                        src={logoKafka}
                        alt="Kafka"
                        className="mx-auto"
                        style={{
                          width: width > 525 && width < 791 ? 90 : 150,
                          paddingRight: width > 525 && width < 791 ? 0 : 20,
                          height: 55,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="carousel-item"
                style={{ height: 300, paddingTop: "20px" }}
              >
                <div
                  className="row mt-3"
                  id="item"
                  style={{
                    paddingLeft: width >= 800 ? "" : "25%",
                    paddingRight: width >= 800 ? "" : "25%",
                  }}
                >
                  <div
                    className="col"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      src={logo_docker}
                      alt="Docker"
                      className="mx-auto"
                      style={{ width: 180, height: 65 }}
                    />
                  </div>
                  <div
                    className="col"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      src={logo_kubernetes}
                      alt="Kubernetes"
                      className="mx-auto"
                      style={{ width: 180, height: 65 }}
                    />
                  </div>
                  <div
                    className="col"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      src={logo_jenkins}
                      alt="Jenkins"
                      className="mx-auto"
                      style={{ width: 180, height: 65 }}
                    />
                  </div>
                </div>
              </div>

              <div
                className="carousel-item"
                style={{ height: 300, paddingTop: "0" }}
              >
                <div
                  className="row mt-3"
                  id="item"
                  style={{
                    paddingLeft: width >= 800 ? "" : "25%",
                    paddingRight: width >= 800 ? "" : "25%",
                  }}
                >
                  <div
                    className="col"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      src={logoMongo}
                      alt="mongoDB"
                      className="mx-auto"
                      style={{ width: 180, height: 60 }}
                    />
                  </div>
                  <div
                    className="col"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      src={logoPostgres}
                      alt="PostgreSQL"
                      className="mx-auto"
                      style={{ width: 180, height: 65 }}
                    />
                  </div>
                  <div
                    className="col"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      src={logoSqlServer}
                      alt="SQL Server"
                      className="mx-auto"
                      style={{ width: 180, height: 75 }}
                    />
                  </div>
                  <div
                    className="col"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      src={logoOracle}
                      alt="Oracle"
                      className="mx-auto"
                      style={{ width: 180, height: 50 }}
                    />
                  </div>
                </div>
              </div>

              <div
                className="carousel-item"
                style={{ height: 300, paddingTop: "0" }}
              >
                <div
                  className="row mt-3"
                  id="item"
                  style={{
                    paddingLeft: width >= 800 ? "" : "25%",
                    paddingRight: width >= 800 ? "" : "25%",
                  }}
                >
                  <div
                    className="col"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      src={logoFlutter}
                      alt="Flutter"
                      className="mx-auto"
                      style={{ width: 180, height: 55 }}
                    />
                  </div>
                  <div
                    className="col"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      src={logoNodeJs}
                      alt="NodeJS"
                      className="mx-auto"
                      style={{ width: 180, height: 50 }}
                    />
                  </div>
                  <div
                    className="col"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      src={logoAngular}
                      alt="Angular"
                      className="mx-auto"
                      style={{ width: 180, height: 65 }}
                    />
                  </div>
                  <div
                    className="col"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      src={logo_android}
                      alt="Android"
                      className="mx-auto"
                      style={{ width: 180, height: 65 }}
                    />
                  </div>
                </div>
              </div>

              <div
                className="carousel-item"
                style={{ height: 300, paddingTop: "10px" }}
              >
                <div
                  className="row mt-3"
                  id="item"
                  style={{
                    paddingLeft: width >= 800 ? "" : "25%",
                    paddingRight: width >= 800 ? "" : "25%",
                  }}
                >
                  <div
                    className="col"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      src={logo_react}
                      alt="ReactJS"
                      className="mx-auto"
                      style={{ width: 180, height: 80 }}
                    />
                  </div>
                  <div
                    className="col"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      src={logo_react_native}
                      alt="React Native"
                      className="mx-auto"
                      style={{ width: 180, height: 80 }}
                    />
                  </div>
                  <div
                    className="col"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      src={logo_redis}
                      alt="Redis"
                      className="mx-auto"
                      style={{ width: 180, height: 65 }}
                    />
                  </div>
                </div>
              </div>

              <div
                className="carousel-item"
                style={{ height: 300, paddingTop: "20px" }}
              >
                <div
                  className="row mt-3"
                  id="item"
                  style={{
                    paddingLeft: width >= 800 ? "" : "25%",
                    paddingRight: width >= 800 ? "" : "25%",
                  }}
                >
                  <div
                    className="col"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      src={logo_apache_spark}
                      alt="Apache Spark"
                      className="mx-auto"
                      style={{ width: 180, height: 65 }}
                    />
                  </div>
                  <div
                    className="col"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      src={logo_apache}
                      alt="Apache Hbase"
                      className="mx-auto"
                      style={{ width: 180, height: 65 }}
                    />
                  </div>
                  <div
                    className="col"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      src={logo_hadoop}
                      alt="Hadoop"
                      className="mx-auto"
                      style={{ width: 180, height: 65 }}
                    />
                  </div>
                </div>
              </div>

              <div
                className="carousel-item"
                style={{ height: 300, paddingTop: "30px" }}
              >
                <div
                  className="row mt-3"
                  id="item"
                  style={{
                    paddingLeft: width >= 800 ? "" : "25%",
                    paddingRight: width >= 800 ? "" : "25%",
                  }}
                >
                  <div
                    className="col"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      src={logo_aws}
                      alt="AWS"
                      className="mx-auto"
                      style={{ width: 180, height: 65 }}
                    />
                  </div>
                  <div
                    className="col"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      src={logo_azure}
                      alt="Azure"
                      className="mx-auto"
                      style={{ width: 180, height: 65 }}
                    />
                  </div>
                  <div
                    className="col"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      src={logo_google_cloud}
                      alt="Google Cloud"
                      className="mx-auto"
                      style={{ width: 180, height: 65 }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-target="#carousel-ambient"
              data-slide="prev"
              style={{ paddingRight: "25px" }}
            >
              <span
                className="carousel-control-prev-icon"
                id="ambiente-prev"
                aria-hidden="true"
                style={{ fill: "rgb(255,255,255" }}
              ></span>
              <span className="sr-only">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-target="#carousel-ambient"
              data-slide="next"
              style={{ paddingLeft: "25px" }}
            >
              <span
                className="carousel-control-next-icon"
                id="ambiente-next"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </button>
          </div>
        ) : (
          <div className="container-fluid bg-ambientes">
            <div className="row" style={{ justifyContent: "center" }}>
              <div
                className="col-sm-6 col-md-4 ctn-ambiente animation"
                style={{ width: "150px", paddingTop: width < 1080 ? 30 : 55 }}
              >
                <div className="row" style={{ justifyContent: "center" }}>
                  <img
                    src={logoplus}
                    alt="C#"
                    className="img-fluid"
                    style={{ maxHeight: "50px" }}
                  />
                  <img
                    src={logoJs}
                    alt="JavaScript"
                    className="img-fluid"
                    style={{ maxHeight: "50px" }}
                  />
                  <img
                    src={logoPython}
                    alt="Python"
                    className="img-fluid"
                    style={{ maxHeight: "60px" }}
                  />
                  <img
                    src={logoKafka}
                    alt="Kafka"
                    className="img-fluid"
                    style={{ maxHeight: "50px" }}
                  />
                  <img
                    src={logoCss}
                    alt="CSS"
                    className="img-fluid"
                    style={{ maxHeight: "50px" }}
                  />
                </div>
              </div>
              <div
                className="col-sm-6 col-md-4 ctn-ambiente animation"
                style={{
                  width: width < 1080 ? "200px" : "240px",
                  paddingTop: 10,
                }}
              >
                <div className="col-sm">
                  <center>
                    <img
                      src={logo_apache_spark}
                      style={{ maxHeight: "60px" }}
                      alt="Apache Spark"
                      className="img-fluid"
                    />
                    <img
                      src={logo_apache}
                      style={{ maxHeight: "60px" }}
                      alt="Apache Hbase"
                      className="img-fluid"
                    />
                    <img
                      src={logo_hadoop}
                      style={{ maxHeight: "60px" }}
                      alt="Hadoop"
                      className="img-fluid"
                    />
                  </center>
                </div>
                <div className="col-sm">
                  <center>
                    <img
                      src={logo_aws}
                      style={{ maxHeight: "50px" }}
                      alt="AWS"
                      className="img-fluid"
                    />
                    <img
                      src={logo_azure}
                      style={{ maxHeight: "60px" }}
                      alt="Azure"
                      className="img-fluid"
                    />
                    <img
                      src={logo_google_cloud}
                      style={{ maxHeight: "60px" }}
                      alt="Google Cloud"
                      className="img-fluid"
                    />
                    <img
                      src={logo_redis}
                      style={{ maxHeight: "50px" }}
                      alt="Redis"
                      className="img-fluid"
                    />
                  </center>
                </div>
              </div>
              <div
                className="col-sm-6 col-md-4 ctn-ambiente animation"
                style={{
                  width: width < 1080 ? "200px" : "240px",
                  paddingTop: width < 1080 ? 30 : "25px",
                }}
              >
                <div className="col">
                  <center>
                    <img
                      src={logo_android}
                      alt="Android"
                      className="img-fluid"
                      style={{ maxHeight: "50px" }}
                    />
                    <br />
                    <br />
                    <img
                      src={logo_react}
                      alt="ReactJS"
                      className="img-fluid"
                      style={{ maxHeight: "60px" }}
                    />
                    <br />
                    <br />
                    <img
                      src={logo_react_native}
                      alt="React Native"
                      className="img-fluid"
                      style={{ maxHeight: "40px" }}
                    />
                    <br />
                    <br />
                    <img
                      src={logoNodeJs}
                      alt="NodeJS"
                      className="img-fluid"
                      style={{ maxHeight: "60px" }}
                    />
                    <br />
                    <br />
                    <img
                      src={logoAngular}
                      alt="Angular"
                      className="img-fluid"
                      style={{ maxHeight: "60px" }}
                    />
                    <img
                      src={logoFlutter}
                      alt="Flutter"
                      className="img-fluid"
                      style={{ maxHeight: "60px" }}
                    />
                  </center>
                </div>
              </div>
              <div
                className="col-sm-6 col-md-4 ctn-ambiente animation"
                style={{ width: width < 1080 ? "200px" : "240px" }}
              >
                <div className="col">
                  <center>
                    <img
                      src={logo_kubernetes}
                      alt="Kubernetes"
                      className="img-fluid"
                      style={{ maxHeight: "40px" }}
                    />
                    <img
                      src={logo_docker}
                      alt="Docker"
                      className="img-fluid"
                      style={{ maxHeight: "40px" }}
                    />
                    <img
                      src={logo_jenkins}
                      alt="Jenkins"
                      className="img-fluid"
                      style={{ maxHeight: "50px" }}
                    />
                    <img
                      src={logoMongo}
                      alt="mongoDB"
                      className="img-fluid"
                      style={{ maxHeight: "40px" }}
                    />
                    <img
                      src={logoPostgres}
                      alt="PostgreSQL"
                      className="img-fluid"
                      style={{ maxHeight: "40px" }}
                    />
                    <img
                      src={logoSqlServer}
                      alt="SQL Server"
                      className="img-fluid"
                      style={{ maxHeight: "60px" }}
                    />
                    <img
                      src={logoOracle}
                      alt="Oracle"
                      className="img-fluid"
                      style={{ maxHeight: "40px", color: "#fff" }}
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
