import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import "../css/main.css";
import "./style.css";
import logoHeader from "../../assets/images/logo-header.svg";
import lngbr from "../../assets/images/lng-br.svg";
import lngen from "../../assets/images/lng-en.svg";
import $ from "jquery";
import { useMediaQuery } from "react-responsive";

export default function Header() {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 950px)" });

  useEffect(() => {
    // i18n.changeLanguage('pt');

    var isOpen = false;
    $(".click-translate").on("click", function (event) {
      if (isOpen) {
        $(".lng").fadeOut(200);
      } else {
        $(".lng").fadeIn(200);
      }

      isOpen = !isOpen;
    });

    $(".language").on("click", function (event) {
      var img = $(event.target).attr("src");
      $(".click-translate").attr("src", img);
      $(".lng").fadeOut(200);
    });

    $(".dropdown").hover(
      function () {
        $(this)
          .find(".dropdown-menu")
          .stop(true, true)
          .delay(200)
          .slideDown(200);
      },
      function () {
        $(this).find(".dropdown-menu").stop(true, true).delay(200).slideUp(200);
      }
    );
  });

  function ChangeLang(lang) {
    i18n.changeLanguage(lang);
  }

  return (
    <>
      <div
        className="header-fitec"
        style={{ display: "flex", flexDirection: "row" }}
      >
        <div className="container-fluid px-0">
          <nav className="navbar navbar-expand-lg">
            <Link className="navbar-brand" to="/">
              <div className="logo">
                <img
                  src={logoHeader}
                  alt="Logomarca Fitec"
                  className="img-fluid"
                />
              </div>
            </Link>

            <button
              className="navbar-toggler bt-menu"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="menu-nav-bar">
                <li className="nav-item">
                  <HashLink
                    smooth
                    className="nav-link"
                    to="/home#a-fitec-section"
                  >
                    {t("menu.sobre")}
                  </HashLink>
                </li>
                <li className="nav-item" style={{ display: "flex" }}>
                  <HashLink className="nav-link" to="/inovacao#inicio-inovacao">
                    {t("atuacao.pd")}
                  </HashLink>
                </li>
                <li className="nav-item" style={{ display: "flex" }}>
                  <HashLink
                    className="nav-link"
                    to="/engenharia#inicio-engenharia"
                  >
                    {t("atuacao.engenharia")}
                  </HashLink>
                </li>
                <li className="nav-item" style={{ display: "flex" }}>
                  <HashLink className="nav-link" to="/solucoes#sustentabilidade">
                    {t("menu.solucao")}
                  </HashLink>
                </li>
                <li className="nav-item" style={{ display: "flex" }}>
                  <HashLink className="nav-link" to="/posts#inicio-publicacoes">
                    {t("menu.publicacoes")}
                  </HashLink>
                </li>
                <li className="nav-item" style={{ display: "flex" }}>
                  <HashLink
                    className="nav-link"
                    to="/oportunidade#trabalhe-conosco"
                  >
                    {t("menu.oportunidades")}
                  </HashLink>
                </li>
                <li
                  className="nav-item"
                  style={{
                    display: "flex",
                  }}
                >
                  <HashLink smooth className="nav-link" to="/embrapii">
                    EMBRAPII
                  </HashLink>
                </li>
                <li className="nav-item" style={{}}>
                  <HashLink
                    smooth
                    className="nav-link"
                    to="/home#contacts-section"
                  >
                    {t("menu.contato")}
                  </HashLink>
                </li>
              </ul>

              {!isMobile && (
                <div className="linguagem">
                  <img
                    src={lngbr}
                    alt=""
                    id="lng-image"
                    className="click-translate animation"
                  />
                  <ul className="lng animation">
                    <li>
                      <img
                        onClick={() => ChangeLang("pt")}
                        src={lngbr}
                        className="language animation"
                        border="0"
                        alt="Traduzir para portugu&ecirc;s"
                      />
                    </li>

                    <li>
                      <img
                        onClick={() => ChangeLang("en")}
                        src={lngen}
                        className="language animation"
                        border="0"
                        alt="Translate to English"
                      />
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </nav>
        </div>

        {isMobile && (
          <div className="linguagem">
            <img
              src={lngbr}
              alt=""
              id="lng-image"
              className="click-translate animation"
            />
            <ul className="lng animation">
              <li>
                <img
                  onClick={() => ChangeLang("pt")}
                  src={lngbr}
                  border="0"
                  className="language animation"
                  alt="Traduzir para portugu&ecirc;s"
                />
              </li>

              <li>
                <img
                  src={lngen}
                  onClick={() => ChangeLang("en")}
                  className="language animation"
                  border="0"
                  alt="Translate to English"
                />
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  );
}
