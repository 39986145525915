import React from "react";
import "./style.css";
import ImageAndTitle from "../../../components/ImageAndTitle";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../../components/UseViewDimensions/useViewDimensions";

import software from "../../../assets/images/img_banner_desenvolvimentoSoftware.jpg";
import eletronicos from "../../../assets/images/img_banner_desenvolvimentoEquipamentoEletronico.jpg";
import ia from "../../../assets/images/img_banner__inteligenciaArtificial.jpg";
import iotInovacao from "../../../assets/images/img_banner__iot.jpg";
import transformDigi from "../../../assets/images/img_banner__transformacaoDigital.jpg";

function Inovation() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 960px)" });
  const { width } = useWindowDimensions();

  return (
    <div>
      <section className="section-intro-page bg-inovacao" id="inicio-inovacao">
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-12 text-center">
              <h1>{t("atuacao.pd")}</h1>
              <ul className="submenu-interno mt-4">
                <li>
                  <a className="page-scroll" href="#software">
                    {t("atuacao.links.1")}
                  </a>
                </li>
                <li>
                  <a className="page-scroll" href="#equipamentos-eletronicos">
                    {t("atuacao.links.2")}
                  </a>
                </li>
                <li>
                  <a className="page-scroll" href="#IA">
                    {t("atuacao.links.3")}
                  </a>
                </li>
                <li>
                  <a className="page-scroll" href="#IOT">
                    {t("atuacao.links.4")}
                  </a>
                </li>
                <li>
                  <a className="page-scroll" href="#transformacao-digital">
                    {t("atuacao.links.5")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="section-sub-page bg-cinza-1" id="inovacao">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p style={{ paddingRight: "15px", paddingLeft: "10px" }}>
                {t("atuacao.text-1.1")}
                <br />
                <br />
                {t("atuacao.text-1.2")}
                <br />
                <br />
                {t("atuacao.text-1.3")}
              </p>
            </div>
          </div>
        </div>
      </section>
      <ImageAndTitle img={software} />
      <section className="section-sub-page" id="software">
        <div className="container">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h2
                style={{
                  justifyContent: "center",
                  fontSize: width <= 700 ? "26px" : "",
                }}
              >
                {" "}
                {t("atuacao.links.1")}
              </h2>
              <p>{t("atuacao.text-1.4")}</p>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: isMobile ? "700px" : "550px",
                  justifyContent: "space-evenly",
                }}
              >
                {!isMobile ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "180px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-2.1")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "180px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-2.2")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "180px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-2.3")}</h3>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-2.1")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-2.2")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-2.3")}</h3>
                    </div>
                  </div>
                )}

                {!isMobile ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "180px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-2.9")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "180px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-2.4")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "180px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-2.5")}</h3>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-2.3")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-2.4")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-2.5")}</h3>
                    </div>
                  </div>
                )}

                {!isMobile ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "180px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-2.6")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "180px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-2.7")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "180px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-2.8")}</h3>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-2.6")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-2.7")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-2.8")}</h3>
                    </div>
                  </div>
                )}
                {!isMobile ? (
                  <></>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-2.9")}</h3>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <ImageAndTitle img={eletronicos} />
      <section
        className="section-sub-page bg-cinza-1"
        id="equipamentos-eletronicos"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2
                style={{
                  justifyContent: "center",
                  fontSize: width <= 700 ? "26px" : "",
                }}
              >
                {t("atuacao.links.2-1")}
              </h2>
              <p>{t("atuacao.text-3.1")}</p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: isMobile ? "700px" : "450px",
              justifyContent: "space-evenly",
            }}
          >
            {!isMobile ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>DFMP – Design for Manufacturing</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>Layout for Manufacturing</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>DFT – Design for Testing</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>ICT – In-Circuit Test</h3>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>DFMP – Design for Manufacturing</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>Layout for Manufacturing</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>DFT – Design for Testing</h3>
                </div>
              </div>
            )}

            {!isMobile ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>AOI – Automatic Optical Inspection</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>AXI – Automatic X-ray Inspection</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-3.2")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-3.3")}</h3>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>ICT – In-Circuit Test</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>AOI – Automatic Optical Inspection</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>AXI – Automatic X-ray Inspection</h3>
                </div>
              </div>
            )}

            {isMobile ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-3.2")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-3.3")}</h3>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="col-12">
              <p style={{ marginTop: "15px" }}>
                {t("atuacao.text-3.4")}
                <br />
                <br />
              </p>
              <h3>{t("atuacao.text-3.5")}</h3>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: isMobile ? "700px" : "600px",
              justifyContent: "space-evenly",
            }}
          >
            {!isMobile ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-3.6")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-3.7")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-3.8")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atucao.text-3.9")}</h3>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-3.6")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-3.7")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-3.8")}</h3>
                </div>
              </div>
            )}

            {!isMobile ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-3.10")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-3.11")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-3.12")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-3.13")}</h3>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atucao.text-3.9")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-3.10")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-3.11")}</h3>
                </div>
              </div>
            )}

            {!isMobile ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-3.14")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-3.15")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-3.16")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-3.17")}</h3>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-3.12")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-3.13")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-3.14")}</h3>
                </div>
              </div>
            )}

            {isMobile ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-3.15")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-3.16")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-white"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-3.17")}</h3>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>
      <ImageAndTitle img={ia} />
      <section className="section-sub-page" id="IA">
        <div className="container">
          <div className="row mb-3">
            <div className="col-12">
              <h2
                style={{
                  justifyContent: "center",
                  fontSize: width <= 700 ? "26px" : "",
                }}
              >
                {t("atuacao.links.3")}
              </h2>
              <p>{t("atuacao.text-4.1")}</p>
              <br />
              <br />
              <h3
                className="mb-3"
                style={{
                  textAlign: "start",
                  fontSize: "15px",
                }}
              >
                {t("atuacao.text-4.2")}
              </h3>

              <h5>{t("atuacao.text-4.3")}</h5>
              <h5>{t("atuacao.text-4.4")}</h5>
              <h5>{t("atuacao.text-4.5")}</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h3>{t("atuacao.text-4.6")}</h3>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              height: isMobile ? "700px" : "450px",
            }}
          >
            {!isMobile ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  className="ctn-rounded ctn-inovacao bg-cinza-1"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-4.7")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-cinza-1"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-4.8")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-cinza-1"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-4.9")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-cinza-1"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>UX (User Experience)</h3>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  className="ctn-rounded ctn-inovacao bg-cinza-1"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-4.7")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-cinza-1"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-4.8")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-cinza-1"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-4.9")}</h3>
                </div>
              </div>
            )}

            {!isMobile ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  className="ctn-rounded ctn-inovacao bg-cinza-1"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-4.10")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-cinza-1"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-4.11")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-cinza-1"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-4.12")}</h3>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  className="ctn-rounded ctn-inovacao bg-cinza-1"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>UX (User Experience)</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-cinza-1"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-4.10")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-cinza-1"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-4.11")}</h3>
                </div>
              </div>
            )}

            {isMobile ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  className="ctn-rounded ctn-inovacao bg-cinza-1"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-4.12")}</h3>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="col-12">
              <h3
                style={{
                  textAlign: "start",
                  marginTop: "15px",
                  fontSize: "15px",
                }}
              >
                {t("atuacao.text-4.13")}
              </h3>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              height: isMobile ? "700px" : "540px",
            }}
          >
            {!isMobile ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  className="ctn-rounded ctn-inovacao bg-cinza-1"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-4.14")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-cinza-1"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-4.15")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-cinza-1"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-4.16")}</h3>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  className="ctn-rounded ctn-inovacao bg-cinza-1"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-4.14")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-cinza-1"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-4.15")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-cinza-1"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-4.16")}</h3>
                </div>
              </div>
            )}

            {!isMobile ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  className="ctn-rounded ctn-inovacao bg-cinza-1"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-4.17")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-cinza-1"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-cinza-1"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-4.19")}</h3>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  className="ctn-rounded ctn-inovacao bg-cinza-1"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-4.17")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-cinza-1"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-cinza-1"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-4.19")}</h3>
                </div>
              </div>
            )}

            {!isMobile ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  className="ctn-rounded ctn-inovacao bg-cinza-1"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-4.20")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-cinza-1"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-4.21")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-cinza-1"
                  style={{
                    display: "flex",
                    width: "180px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-4.22")}</h3>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  className="ctn-rounded ctn-inovacao bg-cinza-1"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-4.20")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-cinza-1"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-4.21")}</h3>
                </div>
                <div
                  className="ctn-rounded ctn-inovacao bg-cinza-1"
                  style={{
                    display: "flex",
                    width: "30%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>{t("atuacao.text-4.22")}</h3>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <ImageAndTitle img={iotInovacao} />
      <section className="section-sub-page bg-cinza-1" id="IOT">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2
                style={{
                  justifyContent: "center",
                  fontSize: width <= 700 ? "26px" : "",
                }}
              >
                {t("atuacao.links.4")}
              </h2>
              <p style={{ paddingRight: "15px", paddingLeft: "15px" }}>
                {t("atuacao.text-5.1")}
                <br />
                <br />
                {t("atuacao.text-5.2")}
                <br />
                <br />
                {t("atuacao.text-5.3")}
                <br />
                <br />
                {t("atuacao.text-5.4")}
              </p>
            </div>
          </div>
        </div>
      </section>
      <ImageAndTitle img={transformDigi} />
      <section className="section-sub-page" id="transformacao-digital">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2
                style={{
                  justifyContent: "center",
                  fontSize: width <= 700 ? "26px" : "",
                }}
              >
                {t("atuacao.links.5")}
              </h2>
              <p style={{ paddingRight: "15px", paddingLeft: "15px" }}>
                {t("atuacao.text-6.1")}
                <br />
                <br />
                {t("atuacao.text-6.2")}
                <br />
                <br />
                {t("atuacao.text-6.3")}
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Inovation;
