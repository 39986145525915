import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";

export default function SectionSolucoes() {
  const { t } = useTranslation();

  return (
    <section className="section-solucao" id="solution-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h2 className="wow fadeIn" data-wow-delay="0.4s">
              {t("menu.solucao")}
            </h2>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-3 px-sm-0 padding-fitec mb-3">
            <HashLink to="/solucoes#inicio-solucao">
              <div
                className="ctn-solucao animation bg-solucao-4 wow fadeInDown"
                data-wow-delay="0.8s"
              >
                <div className="row h-100 align-items-end">
                  <div className="col">
                    <div className="glass-solucao py-5 d-flex">
                      <h6 className="text-center">{t("solucao.digital")}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </HashLink>
          </div>

          <div className="col-sm-3 px-sm-0 padding-fitec mb-3">
            <HashLink to="/solucoes#IOT">
              <div
                className="ctn-solucao animation bg-solucao-3 wow fadeInDown"
                data-wow-delay="0.6s"
              >
                <div className="row h-100 align-items-end">
                  <div className="col">
                    <div className="glass-solucao py-5 d-flex">
                      <h6 className="text-center">{t("solucao.iot")}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </HashLink>
          </div>

          <div className="col-sm-3 px-sm-0 padding-fitec mb-3">
            <HashLink to="/solucoes#MicroredesDeEnergia">
              <div
                className="ctn-solucao animation bg-solucao-2 wow fadeInDown"
                data-wow-delay="0.4s"
              >
                <div className="row h-100 align-items-end">
                  <div className="col">
                    <div className="glass-solucao py-5 d-flex">
                      <h6 className="text-center">{t("solucao.energia")}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </HashLink>
          </div>

          <div className="col-sm-3 px-sm-0 padding-fitec mb-3 ">
            <HashLink to="/solucoes#EngenhariaDeRedesIP">
              <div
                className="ctn-solucao animation bg-solucao-1 wow fadeInDown"
                data-wow-delay="0.2s"
              >
                <div className="row h-100 align-items-end">
                  <div className="col">
                    <div className="glass-solucao py-5 d-flex">
                      <h6 className="text-center">{t("solucao.redeIP")}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </HashLink>
          </div>
        </div>
      </div>
    </section>
  );
}
