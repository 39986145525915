import logo_lei_informatica from "../../../assets/images/fomento/Site-Logo-Lei-da-Informatica.png";
import logo_aneel from "../../../assets/images/fomento/Site-Logo-ANEEL-1.png";
import logo_finep from "../../../assets/images/fomento/Site-Logo-Finep-1.png";
import logo_lei_bem from "../../../assets/images/fomento/Site-Logo-Lei-do-Bem.png";
import logo_softex from "../../../assets/images/fomento/Site-Logo-Softex.png";
import logo_anp from "../../../assets/images/fomento/Site-Logo-ANP-1.png";
import logo_fapesp from "../../../assets/images/fomento/Site-Logo-FAPESP.png";
import logo_mcti from "../../../assets/images/fomento/mcti.jpg";
import logo_bnds from "../../../assets/images/fomento/bnds.jpg";
import logo_facepe from "../../../assets/images/fomento/facepe.jpg";
import logo_rota from "../../../assets/images/fomento/Site-Logo-Rota-2030.png";
import logo_embrapii from "../../../assets/images/logo-embrapii.svg";

function SectionBox() {
  return (
    <>
      <div id="carousel-forment" className="carousel" data-ride="carousel">
        <ol className="carousel-indicators" style={{ bottom: -80 }}>
          <li
            data-target="#carousel-forment"
            data-slide-to="0"
            className="active"
          ></li>
          <li data-target="#carousel-forment" data-slide-to="1"></li>
          <li data-target="#carousel-forment" data-slide-to="2"></li>
          <li data-target="#carousel-forment" data-slide-to="3"></li>
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="row mt-3">
              <div
                className="col"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 10,
                }}
              >
                <img
                  src={logo_lei_informatica}
                  alt="Lei de informática"
                  style={{ width: 250 }}
                  className=" wow fadeIn"
                  data-wow-delay="0.4s"
                />
              </div>
              <div
                className="col"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 10,
                }}
              >
                <img
                  src={logo_aneel}
                  alt="Aneel"
                  style={{ width: 250 }}
                  className=" wow fadeIn"
                  data-wow-delay="0.4s"
                />
              </div>
              <div
                className="col"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 10,
                }}
              >
                <img
                  src={logo_finep}
                  alt="Finep"
                  style={{ width: 250 }}
                  className="wow fadeIn"
                  data-wow-delay="0.4s"
                />
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="row mt-3">
              <div
                className="col"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 10,
                }}
              >
                <img
                  src={logo_lei_bem}
                  alt="Lei do bem"
                  style={{ width: 250 }}
                  className=" wow fadeIn"
                  data-wow-delay="0.4s"
                />
              </div>

              <div
                className="col"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 10,
                }}
              >
                <img
                  src={logo_softex}
                  alt="Softex"
                  style={{ width: 250 }}
                  className=" wow fadeIn"
                  data-wow-delay="0.4s"
                />
              </div>

              <div
                className="col"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 10,
                }}
              >
                <img
                  src={logo_anp}
                  alt="ANP"
                  style={{ width: 250 }}
                  className=" wow fadeIn"
                  data-wow-delay="0.4s"
                />
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="row mt-3">
              <div
                className="col"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 10,
                }}
              >
                <img
                  src={logo_fapesp}
                  alt="FAPESP"
                  style={{ width: 250 }}
                  className=" wow fadeIn"
                  data-wow-delay="0.4s"
                />
              </div>

              <div
                className="col"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 10,
                }}
              >
                <img
                  src={logo_mcti}
                  alt="MCTI"
                  style={{ width: 250 }}
                  className=" wow fadeIn"
                  data-wow-delay="0.4s"
                />
              </div>

              <div
                className="col"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 10,
                }}
              >
                <img
                  src={logo_bnds}
                  alt="BNDS"
                  style={{ width: 250 }}
                  className=" wow fadeIn"
                  data-wow-delay="0.4s"
                />
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="row mt-3">
              <div
                className="col"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 10,
                }}
              >
                <img
                  src={logo_facepe}
                  alt="FACEPE"
                  style={{ width: 250 }}
                  className=" wow fadeIn"
                  data-wow-delay="0.4s"
                />
              </div>

              <div
                className="col"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 10,
                }}
              >
                <img
                  src={logo_rota}
                  alt="Rota"
                  style={{ width: 250 }}
                  className=" wow fadeIn"
                  data-wow-delay="0.4s"
                />
              </div>
              <div
                className="col"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 10,
                }}
              >
                <img
                  src={logo_embrapii}
                  alt="Rota"
                  style={{ width: 250, background: "#fff" }}
                  className=" wow fadeIn"
                  data-wow-delay="0.4s"
                />
              </div>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-target="#carousel-forment"
          data-slide="prev"
          style={{ paddingRight: "35px" }}
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-target="#carousel-forment"
          data-slide="next"
          style={{ paddingLeft: "35px" }}
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </button>
      </div>
    </>
  );
}

export default SectionBox;
