import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "../../components/css/main.css";
import "../../components/css/animate.css";
import mulherCelular from "../../assets/images/img_mulhercelular.jpg";
import SectionAtuacao from "../../components/SectionAtuacao";
import SectionSolucoes from "../../components/SectionSolucoe";
import SectionAmbiente from "../../components/SectionAmbient";
import SectionOportunidades from "../../components/SectionOportunidades";
import SectionClient from "../../components/SectionClient";
import SectionFomento from "../../components/SectionFomento";
import SectionContato from "../../components/SectionContato";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import IntroHome from "../../components/IntroHome";

export default function Home() {
  const { t } = useTranslation();
  const [isHover, setIsHover] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const buttonStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "10px",
    width: "185px",
    height: "40px",
    borderRadius: "20px",
    fontSize: "14px",
    color: !isHover ? "rgb(255, 255, 255)" : "#004e77",
    background: !isHover ? "#004e77" : "rgb(82, 134, 183)",
    paddingBottom: !isMobile ? "0" : "",
    paddingTop: !isMobile ? "0" : "",
  };

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };
  return (
    <div id="main-body">
      <IntroHome />
      <section id="a-fitec-section" style={{ padding: 0 }}>
        {!isMobile ? (
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: "100%",
              }}
            >
              <img src={mulherCelular} alt="mulher no celular" width="100%" />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingRight: "30px",
                  paddingLeft: "30px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <h2
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "26px",
                      color: "#004e77",
                    }}
                  >
                    {t("Fitec.1")}
                  </h2>
                </div>
                <p className="wow fadeIn" data-wow-delay="0.4s">
                  {t("sobre.text-1.1")}
                </p>
                <p className="wow fadeIn" data-wow-delay="0.4s">
                  {t("sobre.text-1.2")}
                </p>
                <p className="wow fadeIn" data-wow-delay="0.4s">
                  {t("sobre.text-1.3")}
                </p>

                <div id="a-fitec-button" style={buttonStyle}>
                  <HashLink
                    className="my-4 animation link wow fadeIn"
                    to="/sobre#imagem-a-fitec"
                    data-wow-delay="0.4s"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={buttonStyle}
                  >
                    <span style={{ color: "white" }}>{t("home.home")}</span>
                  </HashLink>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {isMobile ? (
          <div>
            <div style={{ display: "flex", flexDirection: "row", gap: "16px" }}>

              <div
                id="a-fitec-title"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <h2
                  style={{
                    color: "#004e77",
                    textAlign: "center",
                    marginTop: "30px",
                  }}
                >
                  {t("Fitec.1")}
                </h2>
                <p className="wow fadeIn" data-wow-delay="0.4s">
                  {t("sobre.text-1.1")}
                </p>

                <p className="wow fadeIn" data-wow-delay="0.4s">
                  {t("sobre.text-1.2")}
                </p>

                <p className="wow fadeIn" data-wow-delay="0.4s">
                  {t("sobre.text-1.3")}
                </p>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <HashLink
                    id="saiba-mais-sobre"
                    className="my-4 animation link wow fadeIn"
                    data-wow-delay="0.4s"
                    to="/sobre#imagem-a-fitec"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={buttonStyle}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "18px",
                        color: "white",
                      }}
                    >
                      {t("home.home")}
                    </p>
                  </HashLink>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </section>

      <SectionAtuacao />

      <SectionSolucoes />

      <SectionAmbiente />

      <SectionOportunidades />

      <SectionClient />

      <SectionFomento />

      <SectionContato />
    </div>
  );
}
