// import { url } from "inspector";
import React from "react";
import "./style.css";
import ImageAndTitle from "../../components/ImageAndTitle";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

// import transformacaoDigi from "../../assets/images/img_banner_transformacaodigital.jpg";
import solucoes from "../../assets/images/img_banner__solucoes.jpg";
import engenhariaRede from "../../assets/images/img_banner__engenhariaderede.jpg";
import microrredes from "../../assets/images/img_banner__microrredes.jpg";
import sustentabilidade from "../../assets/images/img_sustentabilidade.jpg";

function Solutions() {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const boxSizeByLanguage = i18n.language === "pt" ? "260px" : "190px";

  return (
    <div>
      <section
        id={"imagem-solucoes"}
        className="section-intro-page bg-transformacao-digital"
        // content={t("menu.solucao")}
      />

      <section class="section-sub-page" id="sustentabilidade">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h2>{t("solucao.sustentabilidade")}</h2>
              <p>
                {t("solucao.text-5.1")}
                <br />
                <br />
                <br />
                <h3
                  style={{
                    font: "inherit",
                    textAlign: "start",
                    fontWeight: "700",
                  }}
                >
                  {t("solucao.text-5.2")}
                </h3>
              </p>
            </div>
            {!isMobile ? (
              <div class="ctn-rounded bg-cinza-1">
                <p>{t("solucao.text-5.3")}</p>
              </div>
            ) : (
              <div
                class="bg-cinza-1"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  borderRadius: "6px",
                }}
              >
                <p>{t("solucao.text-5.3")}</p>
              </div>
            )}
          </div>
        </div>
      </section>
      <ImageAndTitle
        img={sustentabilidade}
        imageByClassName={"section-intro-page bg-sustentabilidade"}
        alt="imagem de uma muda representando sustentabilidade"
      />
      <section class="section-sub-page bg-cinza-1" id="inicio-solucao">
        <div class="container">
          <div class="row mb-4">
            <div class="col-12">
              <h2>{t("solucao.digital")}</h2>
              <p>{t("solucao.text-1.1")}</p>
            </div>
          </div>
          <div class="row row-eq-height">
            <div class="col-12 mb-4">
              {!isMobile ? (
                <div class="ctn-rounded bg-white" style={{ height: "80px" }}>
                  <p>{t("solucao.text-1.2")}</p>
                </div>
              ) : (
                <div
                  class="ctn-rounded bg-white"
                  style={{
                    width: "100%",
                    height: "150px",
                    padding: "20px",
                    paddingTop: "1px",
                  }}
                >
                  <p>{t("solucao.text-1.2")}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <ImageAndTitle
        imageByClassName={"section-intro-page bg-iot"}
        img={solucoes}
      />
      <section class="section-sub-page" id="IOT">
        <div class="container">
          <div class="row mb-3">
            <div class="col-12">
              <h2>{t("solucao.iot")}</h2>
              <p>{t("solucao.text-2.1")}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              {!isMobile ? (
                <div class="ctn-rounded bg-cinza-1" style={{display:'flex', flexWrap:'wrap', paddingLeft: "16px"  }}>
                  <p>
                    {t("solucao.text-2.2")}
                    <br />
                    <br />
                    {t("solucao.text-2.3")}
                  </p>
                </div>
              ) : (
                <div
                  class="ctn-rounded bg-cinza-1"
                  style={{
                    width: "100%",
                    height: boxSizeByLanguage,
                    padding: "20px",
                    paddingTop: "1px",
                  }}
                >
                  <p>
                    {t("solucao.text-2.2")}
                    <br />
                    <br />
                    {t("solucao.text-2.3")}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <ImageAndTitle
        img={engenhariaRede}
        imageByClassName={"section-intro-page bg-engenharia-redes"}
      />
      <section className="section-sub-page" id="EngenhariaDeRedesIP">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 style={{ font: "inherit", fontSize: "36px" }}>
                {t("solucao.redeIP")}
              </h2>
              <p>
                {t("solucao.text-3.1")}
                <br />
                <br />
                {t("solucao.text-3.2")}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        class="section-sub-page bg-cinza-1"
        style={{
          paddingTop: "45px!important",
          paddingBottom: "45px!important",
        }}
      >
        <div class="container">
          <div class="row mb-4">
            <div class="col-12">
              <p>
                {t("solucao.text-3.3")}
                <br />
                <br />
                {t("solucao.text-3.4")}
              </p>
            </div>
          </div>
          <div class="row row-eq-height">
            <div class="col-12 mb-4">
              <div class="ctn-eng">
                <p>
                  {t("solucao.text-3.5")}
                  <br />
                  <br />
                  <span class="symbol-fitec">&#8226;</span>{" "}
                  {t("solucao.text-3.6")}
                  <br />
                  <br />
                  <span class="symbol-fitec">&#8226;</span>{" "}
                  {t("solucao.text-3.7")}
                  <br />
                  <br />
                  <span class="symbol-fitec">&#8226;</span>{" "}
                  {t("solucao.text-3.8")}
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p>{t("solucao.text-3.9")}</p>
            </div>
          </div>
        </div>
      </section>
      <section class="section-sub-page " id="redesip">
        <div class="container">
          <div class="row mb-3">
            <div class="col-12">
              <h3
                style={{
                  font: "inherit",
                  textAlign: "start",
                  fontWeight: "700",
                }}
              >
                {t("solucao.text-3.10")}
              </h3>
              <p>
                {t("solucao.text-3.11")}
                <br />
                <br />
                {t("solucao.text-3.12")}
                <br />
                <br />
                {t("solucao.text-3.13")}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              {!isMobile ? (
                <div
                  class="ctn-rounded bg-cinza-1"
                  style={{
                    height: "200px",
                    padding: "20px",
                    paddingTop: "5px",
                  }}
                >
                  <p>
                    {t("solucao.text-3.14")}
                    <br />
                    <br />
                    {t("solucao.text-3.15")}
                  </p>
                </div>
              ) : (
                <div
                  class="ctn-rounded bg-cinza-1"
                  style={{
                    width: "100%",
                    height: "475px",
                    padding: "20px",
                    paddingTop: "1px",
                  }}
                >
                  <p>
                    {t("solucao.text-3.14")}
                    <br />
                    <br />
                    {t("solucao.text-3.15")}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <ImageAndTitle img={microrredes} imageByClassName={"section-intro-page bg-microredes"} />
      <section class="section-sub-page" id="MicroredesDeEnergia">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h2>{t("solucao.energia")}</h2>
              <p>
                {t("solucao.text-4.1")}
                <br />
                <br />
                {t("solucao.text-4.2")}
                <br />
                <br />
                {t("solucao.text-4.3")}
                <br />
                <br />
                {t("solucao.text-4.4")}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section class="section-sub-page bg-cinza-1">
        <div class="container">
          <div class="row mb-4">
            <div class="col-12">
              <h3
                style={{
                  font: "inherit",
                  textAlign: "start",
                  fontWeight: "700",
                }}
              >
                {t("solucao.text-4.5")}
              </h3>
              <p>
                {t("solucao.text-4.6")}
                <br />
                <br />
                {t("solucao.text-4.7")}
                <br />
                <br />
                {t("solucao.text-4.8")}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section class="section-sub-page " id="redesip">
        <div class="container">
          <div class="row mb-3">
            <div class="col-12">
              <h3
                style={{
                  font: "inherit",
                  textAlign: "start",
                  fontWeight: "700",
                }}
              >
                {t("solucao.text-4.9")}{" "}
              </h3>
              <p>
                {t("solucao.text-4.10")}
                <br />
                <br />
                {t("solucao.text-4.11")}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              {!isMobile ? (
                <div
                  class="ctn-rounded bg-cinza-1"
                  style={{
                    height: "200px",
                    padding: "20px",
                    paddingTop: "5px",
                  }}
                >
                  <p>
                    {t("solucao.text-4.12")}
                    <br />
                    <br />
                    {t("solucao.text-4.13")}
                    <br />
                    <br />
                    {t("solucao.text-4.14")}
                  </p>
                </div>
              ) : (
                <div
                  class="bg-cinza-1"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    borderRadius: "6px",
                    padding: "10px",
                  }}
                >
                  <p>
                    {t("solucao.text-4.12")}
                    <br />
                    <br />
                    {t("solucao.text-4.13")}
                    <br />
                    <br />
                    {t("solucao.text-4.14")}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Solutions;
