import React from "react";
import "../css/main.css";
import "./style.css";
import "../css/owl_carousel/owl.carousel.css";
import "../css/owl_carousel/owl.theme.default.css";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { HashLink } from "react-router-hash-link";
import { Carousel } from "antd";
export default function SectionAtuacao() {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const isDesktop = useMediaQuery({ query: "(min-width: 1224px)" });
  const slidesData = [
    {
      title: `${t("atuacao.pd")}`,
      textLeft: `${t("atuacao.text-1.1")} ${t("atuacao.text-1.2")} `,
      textRight: `${t("atuacao.text-1.3")}`,
      links: [
        {
          title: `${t("atuacao.links.1")}`,
          link: "/inovacao#software",
        },
        {
          title: `${t("atuacao.links.2")}`,
          link: "/inovacao#equipamentos-eletronicos",
        },
        {
          title: `${t("atuacao.links.3")}`,
          link: "/inovacao#IA",
        },
        {
          title: `${t("atuacao.links.4")}`,
          link: "/inovacao#IOT",
        },
        {
          title: `${t("atuacao.links.5")}`,
          link: "/inovacao#transformacao-digital",
        },
      ],
    },
    {
      title: `${t("atuacao.engenharia")}`,
      textLeft: `${t("atuacao.text-7.1")}`,
      textRight: "Texto direita Slide 1",
      links: [
        {
          title: `${t("atuacao.links-2.1")}`,
          link: "/inovacao#software",
        },
        {
          title: `${t("atuacao.links-2.2")}`,
          link: "/inovacao#equipamentos-eletronicos",
        },
      ],
    },
  ];

  const contentStyle = {
    display: "flex",
    justifyContent: "center",
    margin: 0,
    color: "#fff",
    textAlign: "center",
    alignItems: "center",
    fontSize: "24px",
    background: "transparent",
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slidesData.length);
  };
  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1) % slidesData.length);
  };

  return isDesktop ? (
    <section className="section-atuacao" id="field-section">
      <div className="container">
        <h2
          style={{ display: "flex", justifyContent: "center" }}
          data-wow-delay="0.4s"
        >
          {t("menu.atuacao")}
        </h2>

        <div className="row">
          <div className="col-12">
            <div className="carrousel-container">
              <div className="carrousel-content">
                <button
                  className="nav-button-carrousel"
                  onClick={prevSlide}
                  disabled={currentIndex === 0 ? true : false}
                >
                  {"<"}
                </button>

                {slidesData.map((slide, index) => (
                  <div
                    className={`slide-carrousel-${
                      index === currentIndex ? "active" : ""
                    }`}
                    key={index}
                  >
                    <h2>{slide.title}</h2>
                    <div className="text-carrousel">
                      <div className="column-carrousel">
                        <span>{slide.textLeft}</span>
                        <span>{slide.textRight}</span>
                      </div>
                    </div>
                    <div className="links-carrousel">
                      {slide.links.map((link, index) => {
                        if (index === slide.links.length - 1) {
                          return (
                            <HashLink
                              key={index}
                              to={link.link}
                              style={{ color: "#fff" }}
                            >
                              {link.title}
                            </HashLink>
                          );
                        } else {
                          return (
                            <HashLink
                              key={index}
                              to={link.link}
                              style={{ color: "#fff" }}
                            >
                              {link.title} &nbsp;|
                            </HashLink>
                          );
                        }
                      })}
                    </div>
                  </div>
                ))}
                <button
                  className="nav-button-carrousel"
                  onClick={nextSlide}
                  disabled={currentIndex === 1 ? true : false}
                >
                  {">"}
                </button>
              </div>
              <div className="indicators-carrousel">
                {slidesData.map((_, index) => (
                  <button
                    key={index}
                    className={`indicator-carrousel-${
                      index === currentIndex ? "active" : ""
                    }`}
                    onClick={() => setCurrentIndex(index)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : (
    <div className="section-atuacao" id="field-section">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          paddingTop: "10px",
        }}
      >
        <h3 data-wow-delay="0.4s">{t("menu.atuacao")}</h3>
        <Carousel arrows infinite={false}>
          <div style={{alignItems:'center'}}>
            <span style={contentStyle}>{t("atuacao.pd")}</span>
            <div>
              <p>{t("atuacao.text-1.1")}</p>
            </div>
            <div>
              <p>{t("atuacao.text-1.2")}</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-evenly", flexWrap:'wrap', paddingBottom:'26px', gap:'7px' }}>
              <HashLink to={"/inovacao#software"} style={{ color: "#fff" }}>
                {t("atuacao.links.1")}
              </HashLink>
              <HashLink
                to={"/inovacao#equipamentos-eletronicos"}
                style={{ color: "#fff" }}
              >
                {t("atuacao.links.2")}
              </HashLink>
              <HashLink
                to={"/inovacao#IOT"}
                style={{ color: "#fff" }}
              >
                {t("atuacao.links.4")}
              </HashLink>
              <HashLink
                to={"/inovacao#IA"}
                style={{ color: "#fff" }}
              >
                {t("atuacao.links.3")}
              </HashLink>
              <HashLink
                to={"/inovacao#transformacao-digital"}
                style={{ color: "#fff" }}
              >
                {t("atuacao.links.5")}
              </HashLink>
            </div>
          </div>
          <div>
            <span style={contentStyle}>{t("atuacao.engenharia")}</span>
            <div>
              <p>{t("atuacao.text-7.1")}</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-around", paddingTop:'80px' }}>
              <HashLink to={"/inovacao#software"} style={{ color: "#fff" }}>
                {t("atuacao.links-2.1")}
              </HashLink>
              <HashLink
                to={"/inovacao#equipamentos-eletronicos"}
                style={{ color: "#fff" }}
              >
                {t("atuacao.links-2.1")}
              </HashLink>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
}
