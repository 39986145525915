import "./style.css";

export default function AgroConnect() {
  return (
    <div>
      <div>
        <div className="title-container">
          <h1 style={{ fontSize: "26px" }} className="publi-title">
            Com apoio do BNDES, projeto com Internet das Coisas identifica
            aumento de 19,45% na produção de soja.
          </h1>
        </div>
      </div>

      <div className="text-container">
        <p>
          - Parceria entre FITec, NOKIA, ALGAR e UFU conduziu o projeto
          <br />
          <br />
          - Tecnologia incrementou acerto de 12,5% na previsão meteorológica e
          de 142% em <br />
          melhora de sementes <br />
          <br />
          - Projeto ocorre no âmbito do BNDES Pilotos IoT, chamada para seleção
          de projetos-
          <br />
          piloto de Internet das Coisas (IoT)
          <br />
          <br />
          - BNDES já conta com linhas de financiamento para aquisição e
          desenvolvimento de
          <br />
          soluções de IoT para o setor agrícola
          <br />
          <br />
          Apoiado pelo Banco Nacional de Desenvolvimento Econômico e Social
          <br />
          (BNDES), o projeto AgroConnect, identificou que o uso de soluções de
          Internet
          <br />
          das Coisas (IoT) pode aumentar a produção da soja em 19,45%. A
          iniciativa foi
          <br />a primeira concluída no âmbito do{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.bndes.gov.br//wps/portal/site/home/onde-atuamos/inovacao/internet-das-coisas/bndes-projetos-piloto-internet-das-coisas/bndes-pilotos-iot-internet-das-coisas"
          >
            BNDES Pilotos IoT
          </a>
          , chamada realizada em
          <br />
          2018 para avaliação do uso de soluções tecnológicas de IoT em larga
          escala
          <br />
          em três ambientes reais: cidades, saúde e rural. O estudo,
          recém-finalizado, foi
          <br />
          elaborado por meio de uma parceria entre a FITec - Fundação para
          Inovações
          <br />
          Tecnológicas, a Nokia Solutions and Networks, a Algar Farming e a
          <br />
          Universidade Federal de Uberlândia (UFU).
          <br />
          <br />
          O objetivo do AgroConnect foi o de desenvolver, por meio do teste de
          diversas
          <br />
          tecnologias, uma plataforma integrada de diferentes soluções de IoT
          para o<br />
          agronegócio (que envolvem clima, solo, manejo, máquinas, eficiência
          <br />
          energética e eficiência hídrica), já disponíveis no mercado. Esse
          piloto tinha a<br />
          finalidade de aumentar a qualidade de informações do produtor sobr/e o
          cultivo
          <br />
          e, consequentemente, gerar ganhos de produtividade e rentabilidade.
          <br />
          <br />
          O piloto foi realizado nas cinco fazendas do Complexo Gaia, no
          município de
          <br />
          Monte Alegre de Minas (MG), a 82 Km de Uberlândia. Ao todo, foram
          <br />
          implantadas oito tecnologias IoT para o monitoramento de dados, entre
          2019 e<br />
          2021, que englobam sensoriamento remoto de lavouras, monitoramento
          <br />
          meteorológico, do solo, dos recursos hídricos, das operações agrícolas
          e de
          <br />
          ativos, além de outros dispositivos de agricultura de precisão.
          <br />
          <br />
          Além do aumento de produtividade de até 19,45% em sacas de soja por
          <br />
          hectare frente à média histórica de safras anteriores do complexo, os
          estudos
          <br />
          indicaram também um aumento de 12,50% na porcentagem de acerto da
          <br />
          previsão meteorológica e um aumento de 142% na singulação (efeito de
          tornar
          <br />
          uma semente singular, sem falhas ou duplas), dentre outros
          indicadores.
          <br />
          <br />
          Cabe mencionar que os investimentos feitos em equipamentos e serviços
          de
          <br />
          IoT se pagaram já na primeira safra, e os gastos com fornecedores
          caíram 55%
          <br />
          após o segundo ano.
          <br />
          <br />
          A partir de um estudo prático, robusto e independente sobr/e o uso das
          <br />
          tecnologias em ambiente real e em larga escala, o AgroConnect
          comprovou as
          <br />
          vantagens da aplicação de soluções de IoT na agricultura, apresentando
          um
          <br />
          conjunto de oportunidades para replicação destas soluções em culturas
          de
          <br />
          grãos de modo geral, não apenas da soja. Uma delas seria a
          disponibilização
          <br />
          de linhas de crédito que apoiem a difusão das tecnologias no campo.
          <br />
          <br />
          O BNDES conta com linhas de crédito que impulsionam a transformação
          digital
          <br />
          da agricultura, contribuindo para o desenvolvimento de soluções e
          facilitando
          <br />
          investimentos iniciais em IoT realizados por produtores rurais, tais
          como
          <br />o{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.bndes.gov.br//wps/portal/site/home/financiamento/produto/bndes-finame-maquinas-40/"
          >
            BNDES Finame Máquinas 4.0
          </a>
          ,{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.bndes.gov.br//wps/portal/site/home/financiamento/produto/bndes-inovacao/"
          >
            BNDES Finem - Crédito Inovação Direto
          </a>
          ,<br />
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.bndes.gov.br//wps/portal/site/home/financiamento/produto/inovagro/"
          >
            Inovagro
          </a>{" "}
          e{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.bndes.gov.br//wps/portal/site/home/financiamento/produto/cartao-bndes"
          >
            Cartão BNDES
          </a>
          .<br />
          <br />
          <strong>Sobr/e o BNDES Pilotos IoT</strong> – A iniciativa é um
          desdobr/amento do
          <br />
          estudo{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.bndes.gov.br//wps/portal/site/home/conhecimento/pesquisaedados/estudos/estudo-internet-das-coisas-iot/estudo-internet-das-coisas-um-plano-de-acao-para-o-br/asil/!ut/p/z0/nY-7TsNAEEV_JRQuR7skYGitRApCWAhEYbaJxrtDPEm8Y3vHPP6eVSqqFHRzdY_O1RhnGuMifvIelSXiKed3V-7uXrbr/h-rm-smuVhtbvd4_35Zv9XJblubR/uMtANvBhHF1lnJeo9K2maWOgtOOYlHX256XCdtJTYTPTkeeeokphB0rjzAkXtAgYJBWWks5_DuCsnCIpBEzgJbMJWPRiP_cwnDAKBAL0KDDghCDQTpj4_PNyqtf13rgBtcuKDzHN_33D0bU_X9XVL1kaphU!/"
          >
            “Internet das Coisas: um plano de ação para o Br/asil”
          </a>
          , apoiado pelo
          <br />
          BNDES em parceria com Ministério da Ciência, Tecnologia, Inovações e
          <br />
          Comunicações (MCTIC) e conduzido pelo consórcio McKinsey/Fundação
          <br />
          CPqD/Pereira Neto Macedo Advogados.
          <br />
          <br />
          Seu objetivo foi a seleção de projetos-piloto de testes de soluções
          tecnológicas
          <br />
          de Internet das Coisas (IoT) para apoio com recursos não reembolsáveis
          nos
          <br />
          três ambientes priorizados: Cidades, Saúde e Rural. A participação do
          BNDES
          <br />
          com recursos não reembolsáveis chegou a 50% dos itens financiáveis. O
          valor
          <br />
          mínimo do apoio do Banco a cada plano de projetos-piloto foi de R$ 1
          milhão.
          <br />
          <br />
          Um projeto-piloto consiste em um ou mais casos de uso que serão
          testados em
          <br />
          plataformas de experimentação e/ou diretamente em ambientes reais de
          uso,
          <br />
          como cidades, unidades de saúde ou propriedades rurais, cuja execução
          será
          <br />
          por meio de testes em escala real e ambiente controlado, com ênfase em
          <br />
          sistemas abertos e interoperáveis e engajamento de um conjunto amplo
          de
          <br />
          atores, especialmente os usuários.
          <br />
          <br />
          Relatório projeto{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.fitec.org.br//downloads/BNDES_Relatorio_AgroConnect.pdf"
          >
            clique aqui
          </a>
          .
          <br />
          Resumo executivo{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.fitec.org.br//downloads/BNDES_Resumo_Executivo_AgroConnect.pdf"
          >
            clique aqui
          </a>
          .
          <br />
          Saiba mais sobr/e o BNDES Pilotos IoT em{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.bndes.gov.br//wps/portal/site/home/onde-atuamos/inovacao/internet-das-coisas/bndes-projetos-piloto-internet-das-coisas/!ut/p/z0/04_Sj9CPykssy0xPLMnMz0vMAfIjo8zizQPdnT0cTQx93M2DjQwCLcwDjDxMLQ39vU30C7IdFQH0_x1D/"
          >
            www.bndes.gov.br//pilotosiot
          </a>
          .
        </p>
      </div>
    </div>
  );
}
