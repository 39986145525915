import React from "react";
import { Route, BrowserRouter, Navigate, Routes } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Home from "../pages/Home";
import Solucao from "../pages/Solutions";
import Engineering from "../pages/Performance/Engineering";
import Inovation from "../pages/Performance/Inovation";
import AboutFitec from "../pages/AboutFitec";
import ContactUs from "../pages/Contact/ContactUs";
import Posts from "../pages/Posts";
import WorkWithUs from "../pages/Contact/WorkWithUs";
import AgroConnect from "../pages/Posts/Publications/ProjetoAgro/AgroConnect";
import Embrapii from "../pages/Embrapii";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route element={<Home />} path="/home" />
        <Route path="/" element={<Navigate replace to="/home" />} />

        <Route element={<Engineering />} path="/engenharia" />
        <Route element={<Inovation />} path="/inovacao" />
        <Route element={<Solucao />} path="/solucoes" />
        <Route element={<WorkWithUs />} path="/oportunidade" />
        <Route element={<ContactUs />} path="/contato" />
        <Route element={<Posts />} path="/posts" />
        <Route element={<AgroConnect />} path="/agroconnect" />
        <Route element={<AboutFitec />} path="/sobre" />
        <Route element={<Embrapii />} path="/embrapii" />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default AppRoutes;
