import "./style.css";
import ImageAndTitle from "../../../components/ImageAndTitle";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../../components/UseViewDimensions/useViewDimensions";

import redeCelulares from "../../../assets/images/img_banner__redescelulares.jpg";
import redesIp from "../../../assets/images/img_banner__redesIP.jpg";

function Engineering() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 993px)" });
  const { width } = useWindowDimensions();

  return (
    <>
      <section
        className="section-intro-page bg-engenharia"
        id="inicio-engenharia"
      >
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-12 text-center">
              <h1>{t("atuacao.engenharia")}</h1>
              <ul className="submenu-interno mt-4">
                <li>
                  <a className="page-scroll" href="#redescelulares">
                    {t("atuacao.links-2.1")}
                  </a>
                </li>
                <li>
                  <a className="page-scroll" href="#redesip">
                    {t("atuacao.links-2.2")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="section-sub-page">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p style={{ paddingRight: "20px", paddingLeft: "10px" }}>
                {t("atuacao.text-7.1")}
              </p>
            </div>
          </div>
        </div>
      </section>
      <ImageAndTitle img={redeCelulares} />
      <section className="section-sub-page bg-cinza-1" id="redescelulares">
        <div className="container">
          <div className="row mb-4">
            <div className="col-12">
              <h2
                style={{
                  justifyContent: "center",
                  fontSize: width <= 700 ? "30px" : "",
                }}
              >
                {t("atuacao.links-2.1")}
              </h2>
              <p style={{ paddingRight: "20px", paddingLeft: "10px" }}>
                {t("atuacao.text-8.1")}
                <br />
                <br />
                {t("atuacao.text-8.2")}
                <br />
                <br />
                {t("atuacao.text-8.3")}
              </p>
            </div>
          </div>

          {!isMobile ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: "center",
                height: "800px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div className="ctn-eng" style={{ maxWidth: "30%" }}>
                  <h3>{t("atuacao.text-8.5")}</h3>
                  <p>{t("atuacao.text-8.6")}</p>
                </div>
                <div className="ctn-eng" style={{ maxWidth: "30%" }}>
                  <h3>{t("atuacao.text-8.7")}</h3>
                  <p>{t("atuacao.text-8.8")}</p>
                </div>
                <div className="ctn-eng" style={{ maxWidth: "30%" }}>
                  <h3>{t("atuacao.text-8.9")}</h3>
                  <p>{t("atuacao.text-8.10")}</p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div className="ctn-eng" style={{ maxWidth: "30%" }}>
                  <h3>{t("atuacao.text-8.11")}</h3>
                  <p>{t("atuacao.text-8.12")}</p>
                </div>
                <div className="ctn-eng" style={{ maxWidth: "30%" }}>
                  <h3>{t("atuacao.text-8.13")}</h3>
                  <p>{t("atuacao.text-8.14")}</p>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: width <= 993 && width >= 500 ? "1850px" : "1600px",
                justifyContent: "space-evenly",
              }}
            >
              <div className="ctn-eng" style={{ width: "310px" }}>
                <h3 style={{ textAlign: "start", fontSize: "15px" }}>
                  {t("atuacao.text-8.5")}
                </h3>
                <p>{t("atuacao.text-8.6")}</p>
              </div>
              <div className="ctn-eng" style={{ width: "310px" }}>
                <h3 style={{ textAlign: "start", fontSize: "15px" }}>
                  {t("atuacao.text-8.7")}
                </h3>
                <p>{t("atuacao.text-8.8")}</p>
              </div>
              <div className="ctn-eng" style={{ width: "310px" }}>
                <h3 style={{ textAlign: "start", fontSize: "15px" }}>
                  {t("atuacao.text-8.9")}
                </h3>
                <p>{t("atuacao.text-8.10")}</p>
              </div>
              <div className="ctn-eng" style={{ width: "310px" }}>
                <h3 style={{ textAlign: "start", fontSize: "15px" }}>
                  {t("atuacao.text-8.11")}
                </h3>
                <p>{t("atuacao.text-8.12")}</p>
              </div>
              <div className="ctn-eng" style={{ width: "310px" }}>
                <h3 style={{ textAlign: "start", fontSize: "15px" }}>
                  {t("atuacao.text-8.13")}
                </h3>
                <p>{t("atuacao.text-8.14")}</p>
              </div>
            </div>
          )}
        </div>
      </section>
      <ImageAndTitle img={redesIp} />
      <section className="section-sub-page " id="redesip">
        <div className="container">
          <div className="row">
            <div className="col-13">
              <h2
                style={{
                  justifyContent: "center",
                  fontSize: width <= 700 ? "30px" : "",
                }}
              >
                {t("atuacao.links-2.2")}
              </h2>
              <p style={{ paddingRight: "20px", paddingLeft: "10px" }}>
                {t("atuacao.text-9.1")}
                <br />
                <br />
                {t("atuacao.text-9.2")}
                <br />
                <br />
                {t("atuacao.text-9.3")}
                <br />
                <br />
              </p>

              {!isMobile ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    height: "600px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      width: "100%",
                    }}
                  >
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "23%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.4")} </h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "23%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.5")};</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "23%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.6")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "23%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.7")}</h3>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      width: "100%",
                    }}
                  >
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "23%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>High Level Design (HLD);</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "23%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>Low Level Design (LLD);</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "23%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>Method of Procedure Template (MoP-T);</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "23%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>Method of Procedure (MoP);</h3>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      width: "100%",
                    }}
                  >
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "23%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.8")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "23%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.9")}</h3>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    height: "800px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.4")} </h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.5")};</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.6")}</h3>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.7")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.4")} </h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.5")}</h3>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.6")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.7")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.8")}</h3>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.9")}</h3>
                    </div>
                  </div>
                </div>
              )}

              <p
                style={{
                  paddingTop: "20px",
                  paddingRight: "20px",
                  paddingLeft: "10px",
                }}
              >
                {t("atuacao.text-9.10")}
                <br />
                <br />
              </p>

              {!isMobile ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    height: "400px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      width: "100%",
                    }}
                  >
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "23%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.11")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "23%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.12")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "23%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.13")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "23%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.14")}</h3>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      width: "100%",
                    }}
                  >
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "23%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.15")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "23%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.16")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "23%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3> {t("atuacao.text-9.17")}</h3>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    height: "600px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.11")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.12")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.13")}</h3>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.14")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.15")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.16")}</h3>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.17")}</h3>
                    </div>
                  </div>
                </div>
              )}

              <p style={{ paddingRight: "20px", paddingLeft: "10px" }}>
                <br />
                <br />
                {t("atuacao.text-9.18")}
                <br />
                <br />
              </p>

              {!isMobile ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    height: "400px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      width: "100%",
                    }}
                  >
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "23%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.19")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "23%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.20")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "23%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.21")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "23%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.22")}</h3>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      width: "100%",
                    }}
                  >
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "23%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.23")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "23%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.24")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "23%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.25")}</h3>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    height: "580px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.19")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.20")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.21")}</h3>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.22")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.23")}</h3>
                    </div>
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.24")}</h3>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div
                      className="ctn-rounded ctn-inovacao bg-cinza-1"
                      style={{
                        display: "flex",
                        width: "30%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{t("atuacao.text-9.25")}</h3>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Engineering;
