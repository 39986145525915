import React from "react";
import "../css/main.css";
import "./style.css";
import { useMediaQuery } from "react-responsive";
import SectionBoxResponsive from "./box-logo/box_responsive";
import SectionBox from "./box-logo/box";
import { useTranslation } from "react-i18next";

export default function SectionClient() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 890px)" });

  return (
    <section className="section-clientes" id="clients-section">
      <div className="container-fluid">
        <div className="row mb-2">
          <div
            className="col"
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center !important",
            }}
          >
            {!isMobile && (
              <h2
                className="wow fadeIn"
                data-wow-delay="0.4s"
                style={{ color: "#005b8c", textAlign: "center" }}
              >
                {t("home.cliente")}
              </h2>
            )}
            {isMobile && (
              <h2
                className="wow fadeIn"
                style={{
                  color: "#005b8c",
                  fontSize: "2em",
                  textAlign: "center",
                }}
                data-wow-delay="0.4s"
              >
                {t("home.cliente")}
              </h2>
            )}
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          {isMobile && <SectionBoxResponsive />}
          {!isMobile && <SectionBox />}
        </div>
      </div>
    </section>
  );
}
